import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import DestinatairesParActivite from "./DestinatairesParActivite";

const AjouterDestinataireForm = ({ mailingId, destinataires }) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeDialog = () => setIsOpen(false);

  return <>
    <IconButton
      type="submit"
      variant="contained"
      onClick={() => setIsOpen(true)}
      size="large">
      <AddIcon />
    </IconButton>
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle name="form-dialog-title">
        {`Ajouter des destinaires (${destinataires.length})`}
      </DialogTitle>
      <DialogContent>
        <DestinatairesParActivite
          destinataires={destinataires}
          mailingId={mailingId}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Fermer
        </Button>
        {/* <AjouterPaiementButton famille_id={famille_id} {...values} onSuccess={handleSuccess} /> */}
      </DialogActions>
    </Dialog>
  </>;
};

export default AjouterDestinataireForm;
