import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

const mediaHost =
  process.env.NODE_ENV !== "production" ? "http://localhost:8081" : "";

export const MediaSelector = ({ checked, handleToggle }) => {
  const [files, setFiles] = useState(null);


  useEffect(() => {
    fetch(`${mediaHost}/medias/list`)
      .then((res) => res.json())
      .then((datas) => {
        setFiles(datas);
      });
  }, []);

  if (!files) return null;

  return (
    <Grid item>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {files.map((file, idx) => {
          const labelId = `checkbox-list-label-${file}`;

          return (
            <ListItem key={idx} disablePadding>
              <ListItemButton
                role={undefined}
                onClick={handleToggle(file)}
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(file) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={file} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
};
