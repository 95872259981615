import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const GET_ADHERENTS = gql`
  query getAdherents {
    Mjc_adherent {
      id
      nom
      prenom
      famille {
        id
        nom
        prenom
        email
        email2
        telFixe
        telPortable
        telPortable2
      }
      pratiques {
        activite_detail_id
      }
    }
  }
`;

export const useGetAdherents = () => {
  const { loading, data, error } = useQuery(GET_ADHERENTS);

  return {
    loading,
    error,
    adherents:
      data && data.Mjc_adherent
        ? data.Mjc_adherent.filter((a) => a.pratiques.length)
        : [],
  };
};
