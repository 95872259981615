import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import { useCreateFamille } from "../../hooks/useCreateFamille";
import { useHistory } from "react-router-dom";
import { useUpdateFamille } from "../../hooks/useUpdateFamille";
import { useGetVilles } from "../../hooks/useGetVilles";

const FamilleForm = (props) => {
  const { handleClose, open, famille } = props;
  const [values, setValues] = useState(famille);
  const [villeNormalisee, setVilleNormalisee] = useState(
    famille.villeNormalisee? famille.villeNormalisee.nom : ""
  );
  const [inputValue, setInputValue] = React.useState("");
  const { villes } = useGetVilles();
  const history = useHistory();
  const { createFamille, creatingFamille, errorCreatingFamille } =
    useCreateFamille();
  const { updateFamille, loading, error } = useUpdateFamille();

  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const handleChangeSwitch = (name) => (event) => {
    setValues({ ...values, [name]: event.target.checked });
  };

  const handleOnChange = (event, newValue) => {
    if (newValue) {
      setValues({ ...values, ville: newValue.label, codePostal: newValue.codePostal });
      setVilleNormalisee(newValue);
      return;
    }
    setVilleNormalisee("");
  };

  const handleOnInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleSubmit = async (e) => {
    const newFamilleValues = { ...values, ville_id: villeNormalisee.value };
    
    if (!famille.id) {
      const id = await createFamille(newFamilleValues);
      if (id) {
        history.push(`/familles/${id}`);
      }
      return;
    }

    const { __typename, ...formValues } = newFamilleValues;
    await updateFamille(formValues);
    history.push(`/familles/${formValues.id}`);
  };

  useEffect(() => {
    setValues({ ...famille });
    setVilleNormalisee(
      famille.villeNormalisee ? { label: famille.villeNormalisee.nom, value: famille.ville_id } : ""
    );
  }, [famille]);

  return (
    <Dialog
      open={open || error || errorCreatingFamille}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle name="form-dialog-title">
        {!famille.id ? "Ajouter " : "Modifier "} une famille{" "}
        {(loading || creatingFamille) && "..."}
        {error && "!!!!"}
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={1} rowSpacing={2}>
          <Grid item xs={6}>
            <TextField
              autoFocus
              flex
              value={values.nom}
              margin="dense"
              name="nom"
              label="Nom"
              type="text"
              required
              fullWidth
              onChange={handleChange}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="prenom"
              flex
              value={values.prenom}
              label="Prenom"
              type="text"
              fullWidth
              onChange={handleChange}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="adresse"
              value={values.adresse}
              label="Adresse"
              type="text"
              fullWidth
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="adresseComplementaire"
              value={values.adresseComplementaire}
              label="Complément d'adresse"
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="codePostal"
              value={values.codePostal}
              label="codePostal"
              type="text"
              fullWidth
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="ville"
              placeholder="ville"
              value={values.ville}
              type="text"
              fullWidth
              onChange={handleChange}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="telFixe"
              value={values.telFixe}
              label="Téléphone fixe"
              type="text"
              fullWidth
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              id="ville_normalised"
              value={villeNormalisee}
              options={villes.map(({nom, id, code_postal}) => ({ label: nom, value: id, codePostal: code_postal }))}
              onChange={handleOnChange}
              inputValue={inputValue}
              onInputChange={handleOnInputChange}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ville normalisée"
                  name="ville_id"
                  sx={{ marginTop: 1 }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="telPortable"
              value={values.telPortable}
              label="Téléphone portable"
              type="text"
              fullWidth
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="telPortable2"
              value={values.telPortable2}
              label="Téléphone portable 2"
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="telUrgence"
              value={values.telUrgence}
              label="Telephone urgence"
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="email"
              value={values.email}
              label="Email"
              type="text"
              fullWidth
              onChange={handleChange}
              required
              inputProps={{ style: { textTransform: "lowercase" } }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="email2"
              value={values.email2}
              label="Email2"
              type="text"
              fullWidth
              onChange={handleChange}
              inputProps={{ style: { textTransform: "lowercase" } }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="hopital"
              value={values.hopital}
              label="Hopital"
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.photo}
                    onChange={handleChangeSwitch("photo")}
                    value={values.photo}
                  />
                }
                label="Photos"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={values.mailing}
                    onChange={handleChangeSwitch("mailing")}
                    color="primary"
                  />
                }
                label="Mailing"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="avoir"
              value={values.avoir}
              label="Avoir"
              type="text"
              fullWidth
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="avoirMotif"
              value={values.avoirMotif}
              label="Commentaire avoir"
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {famille.id ? "Modifier" : "Créer"} cette famille
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FamilleForm;
