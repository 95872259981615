import React from "react";
import { Query } from '@apollo/client/react/components';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import queries from "../../Familles/queries";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const SelectPublic = ({ type_public, handleChange }) => {
  const classes = useStyles();
  return (
    <Query query={queries.GET_TYPES_PUBLIC}>
      {({ loading, error, data, client }) => {          
        if (error) {
          console.error(error);
          return <div>Error!</div>;
        }

        return (
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="age-simple">Public</InputLabel>
            <Select
              value={type_public}
              onChange={handleChange}
              inputProps={{
                name: "type_public",
                id: "public-simple"
              }}
            >
              {!loading && data.Mjc_type_public.map(t => (<MenuItem value={t.type} key={t.type}>{t.type}</MenuItem>))}
              {loading && <MenuItem value="">...</MenuItem>}
            </Select>
          </FormControl>
        );
      }}
    </Query>
  );
};

export default SelectPublic;
