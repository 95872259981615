import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useGetFamillesSaison } from "../hooks/useGetFamillesSaison";
import { GET_FAMILLE } from "../hooks/useGetFamille";
import { useApolloClient } from "@apollo/client";
import { calculeFacture } from "../common/calculeFacture";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    maxHeight: `calc(100vh - 185px)`,
  },
}));

const getAnomalies = () => {
  const storedAnomalies = window.localStorage.getItem("anomalies");
  return !storedAnomalies ? [] : JSON.parse(storedAnomalies);
};

const PaiementsQuery = () => {
  const classes = useStyles();
  const { familles, loading } = useGetFamillesSaison();
  const [famillesToProcess, setFamillesToProcess] = useState(null);
  const client = useApolloClient();
  const [anomalies, setAnomalies] = useState(getAnomalies());

  const handleStartComputation = () => {
    setAnomalies([]);
    if (familles.length > 0) {
      setFamillesToProcess(familles);
    }
  };

  const handleRefresh = async (id) => {
    try {
      const response = await client.query({
        query: GET_FAMILLE,
        variables: { id },
      });

      const famille = response.data.Mjc_famille[0];
      const { total, totalPaiements } = calculeFacture(famille);
      const manque = totalPaiements - total;

      if (manque === 0) {
        setAnomalies(anomalies.filter((a) => a.id !== id));
      }
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
    if (famillesToProcess && famillesToProcess.length > 0) {
      const [familleToProcess, ...rest] = famillesToProcess;

      const fetchFamille = async () => {
        try {
          const response = await client.query({
            query: GET_FAMILLE,
            variables: { id: familleToProcess.id },
          });

          const famille = response.data.Mjc_famille[0];
          const { total, totalPaiements } = calculeFacture(famille);
          const manque = totalPaiements - total;

          if (manque !== 0) {
            const { adherents, __typename, ...rest } = famille;
            return { ...rest, manque };
          }
          return null;
        } catch (e) {
          console.log({ e });
        }
      };

      fetchFamille().then((result) => {
        console.log({ result });
        if (result) {
          setAnomalies((a) => a.concat([result]));
        }
        if (rest) {
          setFamillesToProcess(rest);
        }
      });
    }
  }, [famillesToProcess, client]);

  useEffect(() => {
    if (
      famillesToProcess &&
      famillesToProcess.length === 0 &&
      anomalies.length > 0
    ) {
      window.localStorage.setItem("anomalies", JSON.stringify(anomalies));
    }
  }, [anomalies, famillesToProcess]);

  if (loading) return null;

  return (
    <Grid container direction="column" spacing={2} justifyItems="center">
      <Grid
        item
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Box>
            <Button
              onClick={handleStartComputation}
              variant="contained"
              color="primary"
            >
              Mettre à jour
            </Button>
          </Box>
        </Grid>
        {famillesToProcess !== null && (
          <Grid item>
            <Box sx={{ width: "200px" }}>
              <LinearProgress
                variant="determinate"
                value={
                  ((familles.length - famillesToProcess.length) * 100) /
                  (familles.length - 1)
                }
              />
            </Box>
          </Grid>
        )}
      </Grid>
      {(famillesToProcess === null || famillesToProcess.length === 0) && (
        <Grid item flexGrow={3}>
          <Paper className={classes.root}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nom</TableCell>
                  <TableCell align="center">Prénom</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {anomalies.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell align="left" component="th" scope="row">
                      <Link to={`/familles/${row.id}`}>{row.nom}</Link>
                    </TableCell>
                    <TableCell align="center">{row.prenom}</TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => handleRefresh(row.id)} title="Re-tester la facturation pour famille">
                        <RefreshIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
export default PaiementsQuery;
