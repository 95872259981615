import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const GET_MAILINGS = gql`
  query getMailings {
    Mjc_mailing(order_by: { created_at: desc }) {
      created_at
      envoyer
      id
      message
      objet
      pieces_jointes
      updated_at
    }
  }
`;

export const useGetMailings = () => {
  const { loading, data, error } = useQuery(GET_MAILINGS, {
    fetchPolicy: "cache-and-network",
  });

  return {
    loading,
    error,
    mailings: data && data.Mjc_mailing ? data.Mjc_mailing : [],
  };
};
