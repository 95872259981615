import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Grid from "@mui/material/Grid";
import ListeActivites from "./ListeActivites";
import ListeAdherents from "./ListeAdherents";
import MailingComplet from "./MailingComplet";
import { useCreateEnvoi } from "../../hooks/useCreateEnvoi";
import { useDeleteEnvoi } from "../../hooks/useDeleteEnvoi";

const DestinatairesParActivite = ({ match, mailingId, destinataires }) => {
  const [activiteId, setActiviteId] = useState(
    "0c58bc94-159b-45e2-a66c-c18edd3b6158"
  );
  const { createEnvoi } = useCreateEnvoi();
  const { deleteEnvoi } = useDeleteEnvoi(activiteId);

  const removeDestinataire = async (id) => deleteEnvoi(id);

  const handleSelect = (id) => {
    setActiviteId(id);
  };

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={4}>
        <ListeActivites activiteId={activiteId} onSelect={handleSelect} />
      </Grid>
      <Grid item xs={4}>
        <ListeAdherents
          activiteId={activiteId}
          destinataires={destinataires}
          addDestinataire={(d) =>
            createEnvoi([
              {
                email: d.email,
                mailing_id: match.params.id,
                resultat: null
              },
            ])
          }
          addDestinataires={(ds) =>
            createEnvoi(
              ds.map((d) => ({
                email: d.email,
                mailing_id: match.params.id,
                resultat: null
              }))
            )
          }
          removeDestinataire={removeDestinataire}
        />
        {!match.params.id && <MailingComplet mailingId={mailingId} />}
      </Grid>
      <Grid item xs={4}>
        <MailingComplet mailingId={mailingId} />
      </Grid>
    </Grid>
  );
};

export default withRouter(DestinatairesParActivite);
