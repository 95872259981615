import { useMutation } from "@apollo/client";
import gql from 'graphql-tag';
import { GET_ENVOIS } from "./useGetEnvois";

export const INSERT_ENVOIS = gql`
  mutation insertEnvois($objects: [Mjc_envoi_insert_input]!) {
    insert_Mjc_envoi(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
export const useCreateEnvoi = () => {
  const [
    insertEnvoi,
    { loading, error },
  ] = useMutation(INSERT_ENVOIS);

  const createEnvoi = async (objects) => {
    try {
      const response = await insertEnvoi({
        variables: { objects },
        refetchQueries: [
          {
            query: GET_ENVOIS,
            variables: { mailing_id: objects[0].mailing_id }
          },
        ],
      });
      return response.data.insert_Mjc_envoi.returning[0].id;       
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  return { createEnvoi, loading, error };
};
