import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const GET_FAMILLES_TO_UPDATE = gql`
  query familleToUpdate {
    Mjc_pratique(
      where: { adherent: { famille: { ville_id: { _is_null: true } } } }
    ) {
      adherent {
        famille {
          nom
          ville
          id
        }
      }
    }
  }
`;

export const useGetFamillesToUpdate = () => {
  const { loading, data, error } = useQuery(GET_FAMILLES_TO_UPDATE);

  return {
    loading,
    error,
    familles:
      data && data.Mjc_pratique
        ? Object.values(data.Mjc_pratique.reduce(
            (acc, pratique) => ({
              ...acc,
              [pratique.adherent.famille.id]: pratique.adherent.famille,
            }),
            {}
          ))
        : [],
  };
};
