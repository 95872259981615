import React from "react";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";
import { uniq } from "lodash";
import "./table.css";
import { useGetFamille } from "../hooks/useGetFamille";
import { calculeFacture } from "../common/calculeFacture";

const Facture = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const { famille, loading, error } = useGetFamille(id);
  
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.error(error);

    return <div>Error!</div>;
  }
  

  const {
    adhFamille,
    avoir,
    totalActivites,
    totalLicences,
    totalAdhesions,
    reductionActivites,
    reductionAdh,
    total,
  } = calculeFacture(famille);

  const {
    nom,
    prenom,
    adresse,
    adresseComplementaire,
    codePostal,
    ville,
    email,
    email2,
    telFixe,
    telPortable,
    telPortable2,
    telUrgence,
    adherents,
    paiements,
    avoirMotif,
  } = famille;


  return (
    <div className="container">
      <div className="inv-title">
        <h1 className="no-margin">MJC Damville Saison 2024-2025</h1>
      </div>
      <div className="inv-header">
        <div>
          <h2>
            {nom} {prenom}
          </h2>
          <ul>
            <li>{adresse}</li>
            {adresseComplementaire && (
              <>
                <li>{adresseComplementaire}</li>
                <br />
              </>
            )}
            <li>
              {codePostal} {ville}
            </li>
            <li>
              {email}
              {email2 && ` / ${email2}`}
            </li>
            {telPortable && (
              <li>
                {telFixe && `${telFixe} / `}
                {telPortable}
                {telPortable2 && ` / ${telPortable2}`}
              </li>
            )}
            {telUrgence && <li>tel Urgence : {telUrgence}</li>}
          </ul>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th align="center">Date</th>
                <th>{format(new Date(), "dd/MM/yyyy")}</th>
              </tr>
              <tr>
                <th align="center">Paiement</th>
                <th>
                  {paiements.length} fois (
                  {uniq(paiements.map((p) => p.type).join(","))})
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div className="inv-body">
        <table>
          <thead>
            <th>Adherent</th>
            <th>Activite</th>
            <th>Professeur</th>
            <th align="right">Prix</th>
          </thead>
          <tbody>
            {adherents.map((adh) =>
              adh.pratiques.map((p) => {
                return (
                  <tr>
                    <td>
                      {adh.prenom} {adh.nom} {adh.dateNaissance}
                    </td>
                    <td>
                      {p.activite_detail.activite.nom} le{" "}
                      {p.activite_detail.semaineJour} de{" "}
                      {p.activite_detail.horaireDebut} à{" "}
                      {p.activite_detail.horaireFin}
                    </td>
                    <td>
                      {p.activite_detail.professeur.nom}{" "}
                      {p.activite_detail.professeur.prenom}
                    </td>
                    <td align="right">
                      {p.activite_detail.montant +
                        (p.activite_detail.activite.tarifComplement
                          ? p.activite_detail.activite.tarifComplement
                          : 0)}{" "}
                      €
                    </td>
                  </tr>
                );
              })
            )}
            {adherents.map((adh) =>
              adh.pratiques
                .filter((p) => p.activite_detail.licence)
                .map((p) => {
                  return (
                    <tr>
                      <td>
                        {adh.prenom} {adh.nom}
                      </td>
                      <td colSpan={2}>
                        {p.activite_detail.activite.nom}{" "}
                        {p.activite_detail.licenceTxt}
                      </td>
                      <td align="right">{p.activite_detail.licence} €</td>
                    </tr>
                  );
                })
            )}
            {!adhFamille &&
              adherents
                .filter((adh) => adh.adhesion && adh.adhesion.montant > 0)
                .map((adh) => {
                  return (
                    <tr>
                      <td>
                        {adh.prenom} {adh.nom}
                      </td>
                      <td colSpan={2}>Adhésion "{adh.adhesion.nom}"</td>
                      <td align="right">{adh.adhesion.montant} €</td>
                    </tr>
                  );
                })}
            {adhFamille && (
              <tr className="t3">
                <td colSpan={3}>Adhésion Famille</td>
                <td align="right">{adhFamille.montant} €</td>
              </tr>
            )}
            {!!avoir && avoir > 0 && (
              <tr className="t2">
                <td colSpan={3}>{avoirMotif}</td>
                <td align="right">-{avoir} €</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="inv-footer">
        <table>
          <tr>
            <th>Total Activités</th>
            <td>{totalActivites.toFixed(2)} €</td>
          </tr>
          <tr>
            <th>Total adhésions</th>
            <td>{totalAdhesions.toFixed(2)} €</td>
          </tr>
          {totalLicences > 0 && (
            <tr>
              <th>Licences</th>
              <td>{totalLicences.toFixed(2)} €</td>
            </tr>
          )}
          {reductionActivites > 0 && (
            <tr>
              <th>Reduction -10%</th>
              <td>- {reductionActivites.toFixed(2)} €</td>
            </tr>
          )}
          {reductionAdh > 0 && (
            <tr>
              <th>Reduction (Dem. Emploi ou Étudiant)</th>
              <td>- {reductionAdh.toFixed(2)} €</td>
            </tr>
          )}
          <tr>
            <th>
              <strong>Total</strong>
            </th>
            <td>
              <strong>{total.toFixed(2)} €</strong>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};
export default withRouter(Facture);
