import React, { useEffect, useState, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useInterval from "use-interval";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import SaveIcon from "@mui/icons-material/Save";
import { MediaSelector } from "./MediaSelector";
import { useGetMailing } from "../../hooks/useGetMailing";
import { useUpdateMailing } from "../../hooks/useUpdateMailing";
import DeleteMailingButton from "./DeleteMailingButton";
import DuplicateMailingButton from "./DuplicateMailingButton";

const CommunicationForm = ({ mailingId }) => {
  const { mailing, loading, error } = useGetMailing(mailingId);
  const [values, setValues] = useState({});
  const [checked, setChecked] = useState([]);
  const [debounce, setDebounce] = useState(0);

  const debounceThresold = 10; // seconds

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setDebounce(debounceThresold)
  };

  const { updateMailing, updatingMailing, errorUpdatingMailing } =
    useUpdateMailing();
  const [showUpdatingIcon, setShowUpdatingIcon] = useState(false);

  useEffect(() => {
    if (!loading && mailing) {
      const { id, objet, message, pieces_jointes, envoyer } = mailing;
      setValues({ id, objet, message, pieces_jointes, envoyer });
      setChecked(
        mailing.pieces_jointes ? mailing.pieces_jointes.split("|") : ""
      );
    }
  }, [loading, mailing]);

  const handleChangeMessage = (message) => {
    setValues((oldValues) => ({ ...oldValues, message }));
    setDebounce(debounceThresold)
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setDebounce(debounceThresold)
  };

  const handleUpdate = useCallback(async ({ envoyer }) => {
    const { id, objet, message } = values;
    await updateMailing({
      id,
      objet,
      message,
      envoyer,
      pieces_jointes: checked.length > 0 ? checked.join("|") : null,
    });
  }, [values, checked, updateMailing]);

  const handleSend = async () => {
    await handleUpdate({ envoyer: true });
  };

  useInterval(() => {
      setDebounce((old) => old - 1);
  }, 1000);

  useEffect(() => {
    if (debounce === 0) {
      const save = async () => {
        await handleUpdate({ envoyer: values.envoyer });
      };
      save();      
    }
  }, [debounce, handleUpdate, values.envoyer])

  useEffect(() => {
    if (updatingMailing) {
      setShowUpdatingIcon(true);
      setTimeout(() => {
        setShowUpdatingIcon(false);
      }, 2000);
    }
  }, [updatingMailing]);


  useEffect(() => {
    if (updatingMailing) {
      setShowUpdatingIcon(true);
      setTimeout(() => setShowUpdatingIcon(false), 2000);
    }
  }, [updatingMailing]);


  if (error) return "Error...";

  if (loading) return null;

  return (
    <>
      <Grid item container spacing={1} direction="column">
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item container alignItems="center" xs={8} spacing={1}>
          {showUpdatingIcon && (
            <Grid item>
              <SaveIcon />
            </Grid>
          )}
        </Grid>
        <Grid item>
          <DeleteMailingButton id={mailingId} />
          <DuplicateMailingButton mailing={mailing} />
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          name="objet"
          placeholder="Objet"
          value={values.objet}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={{ minHeight: "calc(100vh - 300px)" }}>
        <ReactQuill
          value={values.message}
          onChange={handleChangeMessage}
          theme="snow"
        />
      </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        direction="row"
        alignItems="flex-end"
      >
        <MediaSelector checked={checked} handleToggle={handleToggle} />
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSend}
            disabled={values.envoyer || debounce > 0}
          >
            {values.envoyer ? "Envoyé !" : "Envoyer"}
          </Button>
        </Grid>
        {errorUpdatingMailing && debounce === 0 && <h1>Erreur !</h1>}
      </Grid>
    </>
  );
};

export default CommunicationForm;
