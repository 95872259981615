import React, { useState } from "react";
import { Query } from "@apollo/client/react/components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import queries from "../../Familles/queries";

const ListeAdherents = ({
  activiteId,
  addDestinataire,
  addDestinataires,
  removeDestinataire,
  destinataires,
}) => {
  const [idSelected, setIdSelected] = useState("");

  const handleChange = (e, p) => {
    setIdSelected(e.target.value);
  };

  return (
    <Query
      query={queries.GET_ADHERENTS_BY_ACTIVITE}
      variables={{ activite_id: activiteId }}
    >
      {({ loading, error, data, client }) => {
        if (loading) {
          return <div>Loading...</div>;
        }

        if (error) {
          console.error(error);
          return <div>Error!</div>;
        }

        const pratiques = data.Mjc_activite_detail.reduce(
          (memo, ad) => memo.concat(ad.pratiques),
          []
        );

        const pratiquantsToAdd = pratiques
          .filter(
            (p) =>
              p.adherent.famille.email &&
              !destinataires.find(
                (d) => d.email === p.adherent.famille.email
              ) &&
              (idSelected === "" || p.activite_detail_id === idSelected)
          )
          .map((p) => ({ email: p.adherent.famille.email }));

        return (
          <div>
            <InputLabel htmlFor="age-activite_detail_id">Horaire</InputLabel>
            <Select
              value={idSelected}
              onChange={handleChange}
              fullWidth
              inputProps={{
                name: "activite_detail_id",
                id: "activite_detail_id",
              }}
            >
              <MenuItem value="" key="none">
                Toutes
              </MenuItem>
              {data.Mjc_activite_detail.map((p) => (
                <MenuItem value={p.id} key={p.id}>
                  Le {p.semaineJour} de {p.horaireDebut} à {p.horaireFin}
                </MenuItem>
              ))}
              {loading && <MenuItem value="">...</MenuItem>}
            </Select>
            <Grid
              container
              justifyContent="space-between"
              alignItems="stretch"
              sx={{ mt: 2 }}
            >
              <Grid item>
                <ul>
                  {pratiques
                    .filter(
                      (p) =>
                        p.adherent.famille.email &&
                        (idSelected === "" ||
                          p.activite_detail_id === idSelected)
                    )
                    .map((p, idx) => {
                      const exists = destinataires.find(
                        (d) => p.adherent.famille.email === d.email
                      );
                      const { email } = p.adherent.famille;
                      return (
                        <li key={idx}>
                          <button
                            onClick={() =>
                              exists
                                ? removeDestinataire(exists.id)
                                : addDestinataire({ email })
                            }
                          >
                            {exists ? "-" : "+"}
                          </button>{" "}
                          {p.adherent.prenom} {p.adherent.nom}
                        </li>
                      );
                    })}
                </ul>
              </Grid>
              {pratiquantsToAdd.length > 1 && (
                <Grid item>
                  <button
                    style={{ height: "100%" }}
                    onClick={() => addDestinataires(pratiquantsToAdd)}
                  >
                    ++
                  </button>
                </Grid>
              )}
            </Grid>
          </div>
        );
      }}
    </Query>
  );
};

export default ListeAdherents;
