import React, { useEffect } from "react";
import { Query } from '@apollo/client/react/components';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import queries from "../../Familles/queries";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const SelectLieu = ({ lieu, handleChange }) => {
  const classes = useStyles();
  useEffect(() => {
    console.log('lieu', lieu);
  }, [lieu]);  
  return (
    <Query query={queries.GET_LIEUX}>
      {({ loading, error, data, client }) => {          
        if (error) {
          console.error(error);
          return <div>Error!</div>;
        }
        return (
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="age-simple">Lieu</InputLabel>
            <Select
              value={lieu}
              onChange={handleChange}
              name="lieu"
              inputProps={{
                name: "lieu",
                id: "lieu-simple"
              }}
            >
              {!loading && data.Mjc_lieu.map(l => (
                <MenuItem value={l.lieu}>
                  {l.lieu}
                </MenuItem>
              ))}
              {loading && <MenuItem value="">...</MenuItem>}
            </Select>
          </FormControl>
        );
      }}
    </Query>
  );
};

export default SelectLieu;
