import React, { useState } from "react";
import { useCreateVille } from "../../hooks/useCreateVille";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Card, CardActions, CardContent } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

export const AjouterVilleForm = () => {
  const [values, setValues] = useState({ inse: true });
  const { createVille } = useCreateVille();

  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const handleChangeCB = (e) =>
    setValues({ ...values, inse: e.target.checked });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createVille(values);
    setValues({ inse: true });
  };

  return (
    <Card component="form" onSubmit={handleSubmit} sx={{ marginBottom: 3 }}>
      <CardContent>
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <TextField
            name="nom"
            size="small"
            placeholder="Nom de la ville"
            value={values.nom}
            type="text"
            fullWidth
            onChange={handleChange}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <TextField
            name="code_postal"
            placeholder="Code postal"
            size="small"
            value={values.code_postal}
            type="text"
            fullWidth
            onChange={handleChange}
          />
        </FormControl>
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.inse}
                onChange={handleChangeCB}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Ville Inse"
            labelPlacement="right"
          />
        </FormGroup>
      </CardContent>
      <CardActions>
        <Button type="submit">Valider</Button>
      </CardActions>
    </Card>
  );
};
