import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import uuidv4 from "uuid/v4";
import { GET_FAMILLE_PAIEMENTS } from "./useGetFamillePaiements";

const INSERT_PAIEMENT = gql`
  mutation insertPaiement($objects: [Mjc_paiement_insert_input]!) {
    insert_Mjc_paiement(objects: $objects) {
      affected_rows
    }
  }
`;

export const useCreatePaiement = () => {
  const [insertPaiement, { loading, error }] = useMutation(INSERT_PAIEMENT);

  const createPaiement = async (values) => {
    try {
      const response = await insertPaiement({
        variables: {
          objects: values.map((v) => ({ ...v, id: uuidv4() })),
        },
        refetchQueries: [
          {
            query: GET_FAMILLE_PAIEMENTS,
            variables: { famille_id: values.famille_id },
          },
        ],
      });
      return response.data.insert_Mjc_famille.returning[0].id;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  return { createPaiement, loading, error };
};
