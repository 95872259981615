import React from "react";
import { Query } from '@apollo/client/react/components';
import DeleteProgrammationButton from "./DeleteProgrammationButton";
import queries from "../../Familles/queries";

const ListeProgrammation = ({ activiteId, onEdit }) => {
  const handleModif = (e, p) => {
    e.preventDefault();
    onEdit(p);
  }
  return (
    <Query
      query={queries.GET_DETAILS_ACTIVITES_BY_ACTIVITE}
      variables={{ activite_id: activiteId }}
    >
      {({ loading, error, data, client }) => {
        if (loading) {
          return <div>Loading...</div>;
        }

        if (error) {
          console.error(error);

          return <div>Error!</div>;
        }
        return (
          <div>
            {data.Mjc_activite_detail.map(p => (
              <div key={p.id}>
                Le {p.semaineJour} de {p.horaireDebut} à {p.horaireFin} <br />
                Lieu : <strong>{p.lieu}</strong>
                <br />
                Professeur : <strong>{p.professeur.prenom} {p.professeur.nom}</strong>
                <br />
                public <strong>{p.type_public} {p.publicComplement}</strong><br />
                Montant : <strong>{p.montant} € {p.montantComplement}</strong><br />
                {p.licence && <span>Licence : <strong>{p.licence} € {p.licenceTxt}</strong><br /></span>}
                <DeleteProgrammationButton
                  activiteId={activiteId}
                  programmationId={p.id}
                />
                <button onClick={(e) => handleModif(e, p)}>Modifier</button>
              </div>
            ))}
          </div>
        );
      }}
    </Query>
  );
};

export default ListeProgrammation;
