import React, { useState } from "react";
import { Query } from '@apollo/client/react/components';
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import queries from "../../Familles/queries";

const ListeActivites = ({ activiteId, onSelect }) => {
  const [recherche, setRecherche] = useState("");
  const handleChange = e => setRecherche(e.target.value);
  return (
    <Query query={queries.GET_ACTIVITES}>
      {({ loading, error, data, client }) => {
        if (loading) {
          return null;
        }

        if (error) {
          console.error(error);
          return <div>Error!</div>;
        }

        return (
          <>
            <TextField onChange={handleChange} label="rechercher" />
            <List component="nav"  style={{ maxHeight: "calc(100vh - 450px)", overflow: "auto"}}>
              {data && data.Mjc_activite.filter(
                a =>
                  recherche === "" ||
                  a.nom.toLowerCase().indexOf(recherche.toLowerCase()) !== -1
              ).map((a, idx) => (
                <ListItem
                  key={idx}
                  selected={a.id === activiteId}
                  button
                  onClick={() => onSelect(a.id)}
                >
                  <ListItemText primary={a.nom} />
                </ListItem>
              ))}
            </List>
          </>
        );
      }}
    </Query>
  );
};

export default ListeActivites;
