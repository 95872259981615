import React, { useState } from "react";
import { Query } from '@apollo/client/react/components';
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import queries from "../../Familles/queries";
import NouvelleActviviteForm from "./NouvelleActiviteForm";

const ListeActivites = ({ activiteId }) => {
  const [recherche, setRecherche] = useState("");
  const handleChange = e => setRecherche(e.target.value);
  return (
    <Query query={queries.GET_ACTIVITES}>
      {({ loading, error, data, client }) => {
        if (loading) {
          return null;
        }

        if (error) {
          console.error(error);
          return <div>Error!</div>;
        }

        return (
          <>
            <h1>
              Activités{" "}
              {data && (
                <NouvelleActviviteForm
                  categories={Object.keys(
                    data.Mjc_activite.reduce(
                      (m, c) => ({ ...m, [c.categorie]: true }),
                      {}
                    )
                  )}
                />
              )}
            </h1>
            <TextField onChange={handleChange} label="recherche" />
            <List component="nav">
              {data.Mjc_activite.filter(
                a =>
                  recherche === "" ||
                  a.nom.toLowerCase().indexOf(recherche.toLowerCase()) !== -1
              ).map(a => (
                <ListItem
                  component={Link}
                  selected={a.id === activiteId}
                  to={`/programmations/${a.id}`}
                >
                  <ListItemText primary={a.nom} />
                </ListItem>
              ))}
            </List>
          </>
        );
      }}
    </Query>
  );
};

export default ListeActivites;
