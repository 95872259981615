import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CreerActiviteButton from "./CreerActiviteButton";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  inputo: {
    width: "50%",
    padding: theme.spacing(2)
  },
  label: {
    paddingLeft: theme.spacing(2)
  }
}));

const AjouterActiviteForm = ({ categories }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({});

  const handleChange = e =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const closeDialog = () => {
    setIsOpen(false);
  };
  
  return (
    <>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        onClick={() => setIsOpen(true)}
      >
        {" "}
        Ajouter
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle name="form-dialog-title">Créer une activité</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            value={values.nom}
            margin="dense"
            name="nom"
            label="Nom"
            type="text"
            required
            className={classes.input}
            InputLabelProps={{ className: classes.label }}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="description"
            value={values.descrition}
            label="Description"
            type="text"
            className={classes.input}
            InputLabelProps={{ className: classes.label }}
            onChange={handleChange}
          />
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="categorie">Categorie</InputLabel>
            <Select
              value={values.categorie}
              onChange={handleChange}
              inputProps={{
                name: "categorie",
                id: "categorie"
              }}
            >
              {categories.map(c => (
                <MenuItem value={c} key={c}>
                  {c}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Annuler
          </Button>
          <CreerActiviteButton {...values} onSuccess={() => setIsOpen(false)} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AjouterActiviteForm;
