import React, { useState } from "react";
import { Query } from '@apollo/client/react/components';
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import queries from "../../Familles/queries";
import AddPratiqueButton from "./AddPratiqueButton";

const useStyles = makeStyles(theme => ({
  ad: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  titre: {
    textAlign: "center",
    fontWeight: "bold",
  }
}));

const ListeActiviteDetails = ({ pratiques, adherentId, familleId}) => {
  const classes = useStyles();
  const [recherche, setRecherche] = useState("");
  const handleChange = e => setRecherche(e.target.value);

  return (
    <Query query={queries.GET_DETAILS_ACTIVITES}>
      {({ loading, error, data, client }) => {
        if (loading) {
          return <div>Loading...</div>;
        }

        if (error) {
          console.error(error);

          return <div>Error!</div>;
        }
        const details = data.Mjc_activite_detail;
        return (
          <div>
            <TextField
              value={recherche}
              margin="dense"
              name="recherche"
              label="Recherche"
              type="text"
              fullWidth
              onChange={handleChange}
            />
            {details
              .filter(d => {
                const dejaPratiquee = pratiques.find(
                  p => p.activite_detail_id === d.id
                );
                return (
                  !dejaPratiquee &&
                  (recherche === "" ||
                    d.professeur.nom
                      .toLowerCase()
                      .indexOf(recherche.toLowerCase()) !== -1 ||
                    d.activite.nom
                      .toLowerCase()
                      .indexOf(recherche.toLowerCase()) !== -1 ||
                    `${d.montant}`.indexOf(recherche) !== -1)
                );
              })
              .map(a => (
                <Paper key={a.id} className={classes.ad}>
                  <div className={classes.titre}>{a.activite.nom}</div>
                  <div>
                    Le {a.semaineJour} de {a.horaireDebut} à {a.horaireFin}{" "}
                    <br />
                    Lieu : <strong>{a.lieu}</strong>
                    <br />
                    Professeur : <strong>{a.professeur.nom}</strong>
                    <br />
                    {a.type_public}
                    <br />
                    {a.montant}
                    {" € "}
                    {a.montantComplement}<br />
                    {a.licence && <span>Licence : {a.licence} €<br /></span>}                    
                    <AddPratiqueButton
                      adherentId={adherentId}
                      activiteDetailId={a.id}
                      familleId={familleId}
                    />
                  </div>
                </Paper>
              ))}
          </div>
        );
      }}
    </Query>
  );
};

export default ListeActiviteDetails;
