import React from "react";
import uuidv4 from "uuid/v4";
import Button from "@mui/material/Button";
import queries from "../../Familles/queries";
import { Mutation } from '@apollo/client/react/components';

const CreerActiviteButton = ({ onSuccess, ...values }) => {
  return (
    <Mutation mutation={queries.INSERT_ACTIVITE}>
      {(addActivite, { loading, data }) => {
        if (data) {
          onSuccess();
        }        
        return (
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              addActivite({
                variables: {
                  id: uuidv4(),
                  ...values
                },
                refetchQueries: [
                  {
                    query: queries.GET_ACTIVITES
                  }
                ]
              })
            }
          >
            Créer cette activité
          </Button>
        );
      }}
    </Mutation>
  );
};

export default CreerActiviteButton;
