import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useGetFamillesEtPratiques } from "../../hooks/useGetFamillesEtPratiques";
import { useCreateEnvoi } from "../../hooks/useCreateEnvoi";

const getMailingComplet = (datas, mailing_id) =>
  datas
    .filter(
      (f) =>
        f.email &&
        f.email.toLowerCase().indexOf("xxx") === -1 &&
        (f.mailing || f.adherents.find((a) => a.pratiques.length > 0))
    )
    .map((f) => ({ email: f.email, mailing_id }))
    .filter((e) => e.email.toLowerCase().indexOf("xxx") === -1);

const getMailingPreviousAdherents = (datas, mailing_id) =>
  datas
    .filter(
      (f) =>
        f.email &&
        f.mailing &&
        f.previous &&
        f.email.toLowerCase().indexOf("xxx") === -1
    )
    .map((f) => ({ email: f.email, mailing_id }));

const getMailingAdherents = (datas, mailing_id) =>
  datas
    .filter(
      (f) =>
        f.email &&
        f.adherents.find((a) => a.pratiques.length > 0) &&
        f.email.indexOf("xxx") === -1
    )
    .map((f) => ({ email: f.email, mailing_id }));

const MailingComplet = ({ mailingId }) => {
  const { createEnvoi, loading, error } = useCreateEnvoi();
  const {
    familles,
    loading: loadingFamillesPratiques,
    error: errorLoadingFamillesPratiques,
  } = useGetFamillesEtPratiques();

  if (loading || loadingFamillesPratiques) return null;

  if (error || errorLoadingFamillesPratiques) {
    console.error(error, errorLoadingFamillesPratiques);
    return <div>Error!</div>;
  }

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            createEnvoi(getMailingComplet(familles, mailingId));
          }}
        >
          Mailing complet
        </Button>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            createEnvoi(getMailingAdherents(familles, mailingId));
          }}
        >
          Mailing adherents
        </Button>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            createEnvoi(getMailingPreviousAdherents(familles, mailingId));
          }}
        >
          Mailing saison précédente
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <Typography>
            Ajout des destinataires, veuillez patienter...
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default MailingComplet;
