import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { capitalize } from "lodash";
import { GET_FAMILLES } from "./useGetFamilles";

const UPDATE_FAMILLE = gql`
  mutation updateFamille(
    $id: uuid
    $nom: String
    $prenom: String
    $adresse: String
    $adresseComplementaire: String
    $codePostal: String
    $ville: String
    $telFixe: String
    $telPortable: String
    $telPortable2: String
    $telUrgence: String
    $email: String
    $email2: String
    $hopital: String
    $photo: Boolean
    $mailing: Boolean
    $avoir: Float
    $avoirMotif: String
    $ville_id: Int = null
  ) {
    update_Mjc_famille(
      where: { id: { _eq: $id } }
      _set: {
        nom: $nom
        prenom: $prenom
        adresse: $adresse
        adresseComplementaire: $adresseComplementaire
        codePostal: $codePostal
        ville: $ville
        telFixe: $telFixe
        telPortable: $telPortable
        telPortable2: $telPortable2
        telUrgence: $telUrgence
        email: $email
        email2: $email2
        hopital: $hopital
        photo: $photo
        mailing: $mailing
        avoir: $avoir
        avoirMotif: $avoirMotif
        ville_id: $ville_id
      }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateFamille = () => {
  const [handleUpdateFamille, mutationState] = useMutation(UPDATE_FAMILLE);

  const updateFamille = async ({ nom, prenom, ...rest }) => {
    try {
      const response = await handleUpdateFamille({
        variables: {
          ...rest,
          nom: nom.toUpperCase(),
          prenom: capitalize(prenom),
        },
        refetchQueries: [
          {
            query: GET_FAMILLES,
          },
        ],
      });
      return response.data.update_Mjc_famille.affected_rows;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  return { updateFamille, ...mutationState };
};
