import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const GET_ENVOIS = gql`
  query getDestinataires($mailing_id: Int) {
    Mjc_envoi(where: { mailing_id: { _eq: $mailing_id } }) {
      resultat
      id
      envoye
      email
      mailing_id
    }
  }
`;

export const useGetEnvois = (mailing_id) => {
  const { loading, data, error } = useQuery(GET_ENVOIS, {
    variables: { mailing_id },
    pollInterval: 1000,
    skip: !mailing_id
  });

  return {
    loading,
    error,
    destinataires: data && data.Mjc_envoi ? data.Mjc_envoi : [],
  };
};
