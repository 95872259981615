import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SelectReduction from "./SelectReduction";
import SelectAdhesion from "./SelectAdhesion";
import ModifierAdherentButton from "./ModifierAdherentButton";
import AjouterAdherentButton from "./AjouterAdherentButton";

const AdherentForm = ({ adherent, familleId, modeAjout, inse }) => {
  const [values, setValue] = useState(adherent);

  const handleChange = (e) =>
    setValue({ ...values, [e.target.name]: e.target.value });

  useEffect(() => {
    setValue({ ...adherent });
  }, [adherent]);

  return (
    <Grid container direction="row" rowSpacing={3} columnSpacing={1}>
      <Grid item sm={4}>
        <TextField
          autoFocus
          value={values.nom}
          margin="dense"
          name="nom"
          label="Nom"
          type="text"
          required
          onChange={handleChange}
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
      </Grid>
      <Grid item sm={4}>
        <TextField
          value={values.prenom}
          margin="dense"
          name="prenom"
          label="Prénom"
          type="text"
          required
          onChange={handleChange}
          inputProps={{ style: { textTransform: "capitalize" } }}
        />
      </Grid>
      <Grid item sm={4}>
        <TextField
          value={values.dateNaissance}
          margin="dense"
          name="dateNaissance"
          label="Date de naissance"
          type="text"
          required
          onChange={handleChange}
        />
      </Grid>
      <Grid item sm={6}>
        <SelectReduction
          handleChange={handleChange}
          reduction_id={values.reduction_id}
        />
      </Grid>
      <Grid item sm={6}>
        <SelectAdhesion
          handleChange={handleChange}
          adhesion_id={values.adhesion_id}
          inse={inse}
        />
      </Grid>
      <Grid item sm={12}>
        {values.id && !modeAjout && (
          <ModifierAdherentButton {...values} famille_id={familleId} />
        )}
        {modeAjout && (
          <AjouterAdherentButton {...values} famille_id={familleId} />
        )}
      </Grid>
    </Grid>
  );
};

export default AdherentForm;
