import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const GET_MAILING = gql`
  query getMailing($id: Int!) {
    Mjc_mailing(where: { id: { _eq: $id } }) {
      created_at
      envoyer
      id
      message
      objet
      pieces_jointes
      updated_at
    }
  }
`;

export const useGetMailing = (id) => {
  const { loading, data, error } = useQuery(GET_MAILING, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });

  return {
    loading,
    error,
    mailing: data && data.Mjc_mailing ? data.Mjc_mailing[0] : null,
  };
};
