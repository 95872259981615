import React from "react";
import queries from "../../Familles/queries";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { Mutation } from '@apollo/client/react/components';

const SupprimerProfesseurButton = ({ id, className, disabled }) => {
  return (
    <Mutation mutation={queries.DELETE_PROFESSEUR}>
      {(removeProfesseur, { loading, data }) => {
        return (
          <Button
            onClick={() => {
              // eslint-disable-next-line
              if (confirm("Supprimer ce professeur ?")) {
                removeProfesseur({
                  variables: { id },
                  optimisticResponse: {},
                  refetchQueries: [
                    {
                      query: queries.GET_PROFESSEURS
                    }
                  ]
                });
              }
            }}
            variant="outlined"
            disabled={disabled}
            className={className}
          >
            <DeleteIcon title="supprimer" />
          </Button>
        );
      }}
    </Mutation>
  );
};

export default SupprimerProfesseurButton;
