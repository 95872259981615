import React from "react";
import Button from "@mui/material/Button";
import { useCreatePaiement } from "../../hooks/useCreatePaiement";
import { Box } from "@mui/material";

const AjouterPaiementButton = ({ values }) => {
  const { createPaiement, error } = useCreatePaiement();

  if (error) {
    console.error(error);
    return <div>Error!</div>;
  }

  const plural = values.length > 1 ? "s" : "";
  return (
    <Box sx={{ textAlign: "center" }}>
      <Button
        color="primary"
        variant="contained"
        sx={{ marginTop: 1 }}
        onClick={async () =>
          await createPaiement(values)
        }
      >
        {`Ajouter ce${plural} paiement${plural}`}
      </Button>
    </Box>
  );
};

export default AjouterPaiementButton;
