import { Box } from "@mui/material";
import React from "react";
import { useGetFamillesToUpdate } from "../../hooks/useGetFamillesToUpdate";
import { MajVilleFamilleForm } from "./MajVilleFamilleForm";

export const ListeFamillesToUpdate = ({ villes }) => {
  const { familles, loading } = useGetFamillesToUpdate();

  if (loading) return <p>Chargmement...</p>;

  return (
    <Box
      sx={{ maxHeight: "calc(100vh - 200px)", overflow: "auto", paddingRight: 3, paddingLeft: 3 }}
    >
      {familles.map((famille) => (
        <MajVilleFamilleForm villes={villes} famille={famille} />
      ))}
    </Box>
  );
};
