import React, { useState } from "react";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ApolloConsumer } from "@apollo/client";
import FamilleForm from "./components/FamilleForm";
import RechercheForm from "./components/RechercheForm";
import Loader from "../common/Loader";
import { useGetFamilles } from "../hooks/useGetFamilles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    maxHeight: `calc(100vh - 185px)`,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const FamillesQuery = () => {
  const classes = useStyles();
  const { loading, error, familles } = useGetFamilles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogEditOpen, setDialogEditOpen] = useState(false);
  const [searchDatas, setSearchDatas] = useState(null);
  const [selected, setSelected] = useState({});
  const closeDialog = () => setDialogOpen(false);
  const openDialog = () => setDialogOpen(true);
  const openEditDialog = () => setDialogEditOpen(true);
  const closeEditDialog = () => setDialogEditOpen(false);

  const handleEditClicked = (famille) => {
    setSelected(famille);
    openEditDialog();
  };

  if (loading) {
    return <Loader />;
  }

  if (error) return <p>{JSON.stringify(error)}</p>;

  return (
    <Grid container direction="column" spacing={2}>
      <FamilleForm famille={{}} open={dialogOpen} handleClose={closeDialog} />

      <FamilleForm
        famille={selected}
        open={dialogEditOpen}
        handleClose={closeEditDialog}
      />
      <Grid
        container
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignContent="center"
      >
        <Grid item>
          <Button onClick={openDialog} color="primary" variant="contained">
            + Nouvelle famille
          </Button>
        </Grid>
        <Grid item>
          <ApolloConsumer>
            {(client) => (
              <RechercheForm setSearchDatas={setSearchDatas} client={client} />
            )}
          </ApolloConsumer>
        </Grid>
      </Grid>
      <Grid item>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Nom</TableCell>
                <TableCell align="center">Prénom</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {(searchDatas || familles).map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center" component="th" scope="row">
                    {row.nom}
                  </TableCell>
                  <TableCell align="center">{row.prenom}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                      component={Link}
                      to={`/familles/${row.id}`}
                    >
                      Activités
                    </Button>
                    <Button
                      onClick={() => handleEditClicked(row)}
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                    >
                      Modifier
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                      to={`/factures/${row.id}`}
                      component={Link}
                    >
                      Facture
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default FamillesQuery;
