import React from "react";
import { Query } from "@apollo/client/react/components";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import queries from "../../Familles/queries";
import { Typography } from "@mui/material";

const SelectTypePaiement = ({ type, handleChange }) => {
  return (
    <Query query={queries.GET_TYPES_PAIEMENT}>
      {({ loading, error, data }) => {
        if (error) {
          console.error(error);
          return <div>Error!</div>;
        }
        return (
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <Typography id="type-paiement" gutterBottom>
              Type de paiement
            </Typography>
            <Select
              value={type}
              onChange={handleChange}
              disabled={loading}
              name="type"
              inputProps={{
                name: "type",
                id: "type-paiement",
              }}
            >
              {!loading &&
                data.Mjc_type_paiment.map((t) => (
                  <MenuItem value={t.type}>{t.type}</MenuItem>
                ))}
              {loading && <MenuItem value="">...</MenuItem>}
            </Select>
          </FormControl>
        );
      }}
    </Query>
  );
};

export default SelectTypePaiement;
