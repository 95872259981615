import React from "react";
import { Query } from "@apollo/client/react/components";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import queries from "../../Familles/queries";

const SelectReduction = ({ reduction_id, handleChange }) => {
  return (
    <Query query={queries.GET_REDUCTIONS}>
      {({ loading, error, data }) => {
        if (error) {
          console.error(error);
          return <div>Error!</div>;
        }
        return (
          <>
            <InputLabel htmlFor="age-simple">Reduction</InputLabel>
            <Select
              value={reduction_id}
              fullWidth
              onChange={handleChange}
              inputProps={{
                name: "reduction_id",
                id: "reduction_id",
              }}
            >
              {!loading &&
                data.Mjc_reduction.map((p, idx) => (
                  <MenuItem value={p.id} key={idx + 1}>{p.nom}</MenuItem>
                ))}
              {loading && <MenuItem value="" key={0}>...</MenuItem>}
            </Select>
          </>
        );
      }}
    </Query>
  );
};

export default SelectReduction;
