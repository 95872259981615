import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { GET_MAILINGS } from "./useGetMailings";

const UPDATE_MAILING = gql`
  mutation UpdateMjcMailing(
    $id: Int!
    $envoyer: Boolean = false
    $message: String = ""
    $objet: String = ""
    $pieces_jointes: String = ""
  ) {
    update_Mjc_mailing(
      where: { id: { _eq: $id } }
      _set: {
        envoyer: $envoyer
        message: $message
        objet: $objet
        pieces_jointes: $pieces_jointes
      }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateMailing = () => {
  const [handleUpdateMailing, { loading, error }] = useMutation(UPDATE_MAILING);

  const updateMailing = async (values) => {
    try {
      const response = await handleUpdateMailing({
        variables: { ...values },
        refetchQueries: [
          {
            query: GET_MAILINGS,
          },
        ],
      });
      return response.data.update_Mjc_mailing.affected_rows;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  return {
    updateMailing,
    updatingMailing: loading,
    errorUpdatingMailing: error,
  };
};
