import React from "react";
import Button from "@mui/material/Button";
import { useCreateAdherent } from "../../hooks/useCreateAdherent";
import { useHistory } from "react-router-dom";

const AjouterAdherentButton = (values) => {
  const history = useHistory();
  const { createAdherent } = useCreateAdherent();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const id = await createAdherent(values);
    history.push(`/familles/${values.famille_id}/adherents/${id}`);
  };

  return (
    <Button
      fullWidth
      size="large"
      color="primary"
      variant="contained"
      onClick={handleSubmit}
    >
      Ajouter
    </Button>
  );
};

export default AjouterAdherentButton;
