import * as React from "react";
import { Route, Switch } from "react-router-dom";
import Familles from "./Familles";
import Professeurs from "./Professeurs";
import Famille from "./Famille";
import Adherents from "./Adherents";
import Facture from "./Facture";
import Programmation from "./Programmation";
import Mailing from "./Mailing";
import Villes from "./Villes";
import Paiements from "./Paiements";
import AnomaliesPaiement from "./AnomaliesPaiement";
import Media from "./Media";

const NotFound = () => <h1>Pas trouvé</h1>;
const Homepage = () => <h1>MJC Damville Admin 2.0</h1>;

const MainRouter = (props) => {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/" component={Homepage} exact />
        <Route path="/programmations/:id?" component={Programmation} exact />
        <Route path="/anomalies_paiement" component={AnomaliesPaiement} exact />
        <Route path="/paiements" component={Paiements} exact />
        <Route path="/familles" component={Familles} exact />
        <Route path="/villes" component={Villes} exact />
        <Route path="/adherents" component={Adherents} exact />
        <Route path="/familles/:id" component={Famille} exact/>
        <Route path="/familles/:id/adherents/:adherentId" component={Famille} exact/>
        <Route path="/factures/:id" component={Facture} exact />
        <Route path="/professeurs/:id?" component={Professeurs} exact />
        <Route path="/media" component={Media} exact />
        <Route path="/mailings/:id?" component={Mailing} exact />
        <Route component={NotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default MainRouter;
