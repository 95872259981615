import React, { useState } from 'react';
import Button from '@mui/material/Button';

const GenerateButton = (props) => {
	const [ state, setState ] = useState('Générer le document');
	const handleClick = (e) => {
		if (state === 'Générer le document') {
			e.preventDefault();
			fetch('print/paiements', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ paiements: props.paiements })
			})
				.then((res) => res.json())
				.then(() => {
					setState('Télécharger le document');
					setTimeout(
						() =>
							fetch('print/clearDoc/paiements').then(() =>
								setState('Générer le document')
							),
						4000
					);
				});
		}
	};
	return (
		<Button
			variant="text"
			onClick={handleClick}
			href={state === 'Générer le document' ? null : 'print/paiements.pdf'}
		>
			{state}
		</Button>
	);
};

export default GenerateButton;
