import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const mediaHost =
  process.env.NODE_ENV !== "production" ? "http://localhost:8081" : "";

const Media = () => {
  const [cleared, setCleared] = useState(false);

  const clearMedias = async () => {
    await fetch(`${mediaHost}/medias`, { method: "DELETE" });
    setCleared(true);
  };

  return (
    <>
      <form
        method="POST"
        action={`${mediaHost}/medias/upload`}
        enctype="multipart/form-data"
      >
        <input type="file" name="myFile" />
        <input type="submit" />
      </form>
      <Box mt={3}>
        {cleared ? (
          <Typography variant="h5">Vidé !</Typography>
        ) : (
          <Button onClick={clearMedias} color="primary" variant="outlined">
            Vider le dossier
          </Button>
        )}
      </Box>
    </>
  );
};

export default Media;
