import React from 'react';
import { withRouter } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const CustomAppBar = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            MJC Admin
          </Typography>
          <Button color="inherit" onClick={() => props.history.push('/paiements')}>Paiements</Button>
          <Button color="inherit" onClick={() => props.history.push('/mailings')}>Mailing</Button>
          <Button color="inherit" onClick={() => props.history.push('/programmations')}>Activités</Button>
          <Button color="inherit" onClick={() => props.history.push('/professeurs')}>Professeurs</Button>
          <Button color="inherit" onClick={() => props.history.push('/villes')}>Villes</Button>
          <Button color="inherit" onClick={() => props.history.push('/familles')}>Familles</Button>
          <Button color="inherit" onClick={() => props.history.push('/adherents')}>Adherents</Button>
          <Button color="inherit" onClick={() => props.history.push('/media')}>Media</Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withRouter(CustomAppBar);
