import React from "react";
import queries from "../../Familles/queries";
import { Mutation } from "@apollo/client/react/components";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";

const DeletePaiementButton = ({ id, familleId }) => {
  return (
    <Mutation mutation={queries.DELETE_PAIEMENT}>
      {(removePaiement, { loading, data }) => {
        return (
          <IconButton
            size="small"
            onClick={() =>
              removePaiement({
                variables: { id },
                optimisticResponse: {},
                refetchQueries: [
                  {
                    query: queries.GET_FAMILLE_PAIEMENTS,
                    variables: { familleId },
                  },
                ],
              })
            }
          >
            <DeleteIcon />
          </IconButton>
        );
      }}
    </Mutation>
  );
};

export default DeletePaiementButton;
