import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import DeletePratiqueButton from "./DeletePratiqueButton";

const useStyles = makeStyles((theme) => ({
  pratique: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  titre: {
    textAlign: "center",
    fontSize: "1.1em",
    fontWeight: "bold",
  },
}));

const ListePratiques = ({ pratiques, familleId }) => {
  const classes = useStyles();
  return (
    <>
      {pratiques.map((p) => (
        <Paper key={p.id} elevation={2} className={classes.pratique}>
          <div className={classes.titre}>{p.activite_detail.activite.nom}</div>
          <div>
            Le {p.activite_detail.semaineJour} de{" "}
            {p.activite_detail.horaireDebut} à {p.activite_detail.horaireFin}{" "}
            <br />
            Lieu : <strong>{p.activite_detail.lieu}</strong>
            <br />
            Professeur : <strong>{p.activite_detail.professeur.nom}</strong>
            <br />
            {p.activite_detail.type_public}
            <br />
            <DeletePratiqueButton
              familleId={familleId}
              adherentId={p.adherent_id}
              activiteDetailId={p.activite_detail_id}
            />
          </div>
        </Paper>
      ))}
    </>
  );
};

export default ListePratiques;
