import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { capitalize } from "lodash";
import uuidv4 from "uuid/v4";
import { GET_FAMILLE } from "./useGetFamille";

const INSERT_ADHERENT = gql`
  mutation insertAdherent(
    $id: uuid
    $nom: String
    $prenom: String
    $dateNaissance: String
    $famille_id: uuid
    $adhesion_id: uuid
    $reduction_id: uuid
  ) {
    insert_Mjc_adherent(
      objects: {
        id: $id
        nom: $nom
        prenom: $prenom
        famille_id: $famille_id
        adhesion_id: $adhesion_id
        reduction_id: $reduction_id
        dateNaissance: $dateNaissance
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const useCreateAdherent = () => {
  const [insertAdherent, { loading, error }] = useMutation(INSERT_ADHERENT);

  const createAdherent = async ({ nom, prenom, famille_id, ...rest }) => {
    try {
      const response = await insertAdherent({
        variables: {
          ...rest,
          id: uuidv4(),
          famille_id,
          nom: nom.toUpperCase(),
          prenom: capitalize(prenom),
        },
        refetchQueries: [
          {
            query: GET_FAMILLE,
            variables: { id: famille_id },
          },
        ],
      });
      return response.data.insert_Mjc_adherent.returning[0].id;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  return { createAdherent, loading, error };
};
