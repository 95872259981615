import React from "react";
import ReactDOM from "react-dom";
import { Route, Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import theme from "./theme";
import "./index.css";
import history from "./utils/history";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

const mainRoutes = (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router history={history}>
      <Route path="/" component={App} />
    </Router>
  </ThemeProvider>
);

ReactDOM.render(mainRoutes, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
