import React from "react";
import queries from "../../Familles/queries";
import { Mutation } from '@apollo/client/react/components';

const SupprimeButton = ({
  adherentId,
  activiteDetailId,
  familleId,  
}) => {
  return (
    <Mutation mutation={queries.DELETE_PRATIQUE}>
      {(removePratique, { loading, data }) => {
        return (
          <button
            onClick={() =>
              removePratique({
                variables: { adherentId, activiteDetailId },
                optimisticResponse: {},
                refetchQueries: [
                  {
                    query: queries.GET_FAMILLE,
                    variables: { id: familleId }
                  }
                ],
              })
            }
          >
            x
          </button>
        );
      }}
    </Mutation>
  );
};

export default SupprimeButton;
