import React from "react";
import queries from "../../Familles/queries";
import { Mutation } from '@apollo/client/react/components';

const AjoutButton = ({ adherentId, activiteDetailId, familleId }) => {
  return (
    <Mutation mutation={queries.INSERT_PRATIQUE}>
      {(addTodo, { loading, data }) => {
        return (
          <button
            onClick={() =>
              addTodo({
                variables: { adherentId, activiteDetailId },
                refetchQueries: [
                  {
                    query: queries.GET_FAMILLE,
                    variables: { id: familleId }
                  }
                ],
              })
            }
          >
            +
          </button>
        );
      }}
    </Mutation>
  );
};

export default AjoutButton;
