import React, { useState } from "react";
import AjouterPaiementButton from "./AjouterPaiementButton";
import {
  Grid,
  MenuItem,
  Select,
  Slider,
  Typography,
  FormControl,
  Box,
} from "@mui/material";
import SelectTypePaiement from "./SelectTypePaiement";

export const PaiementsAutoForm = ({ total, dates, famille_id }) => {
  const [debut, setDebutPaiements] = useState(0);
  const [nombrePaiements, setNombrePaiements] = useState(1);
  const [type, setTypePaiement] = useState("Chèque");

  const handleChangeNombrePaiements = (event, value) =>
    setNombrePaiements(value);

  const handleChange = (e) => setDebutPaiements(e.target.value);
  const handleChangeTypePaiement = (e) => setTypePaiement(e.target.value);

  const rest = total % nombrePaiements;

  console.log(
    debut,
    nombrePaiements - debut,
    dates.slice(debut, nombrePaiements)
  );
  const echeancier = dates
    .slice(debut, nombrePaiements + debut)
    .map((date, index, range) => ({
      date,
      type,
      montant:
        index === range.length - 1
          ? (Math.floor(total / nombrePaiements) + rest).toFixed(2)
          : Math.floor(total / nombrePaiements),
      famille_id,
    }));
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography id="input-slider">
          {nombrePaiements} paiement{nombrePaiements > 1 ? "s" : ""}
        </Typography>
        <Slider
          value={nombrePaiements}
          min={1}
          max={dates.length - debut}
          aria-label="Default"
          valueLabelDisplay="auto"
          onChange={handleChangeNombrePaiements}
        />
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <Typography id="input-slider" gutterBottom>
            Date 1er paiement
          </Typography>
          <Select
            value={debut}
            onChange={handleChange}
            inputProps={{
              name: "date",
              id: "date",
            }}
          >
            {dates.map((d, index) => (
              <MenuItem value={index} key={d}>
                {d}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <SelectTypePaiement
          handleChange={handleChangeTypePaiement}
          type={type}
        />
      </Grid>
      <Grid item xs={6}>
        <Box ml={3}>
          <Typography variant="h5" align="center">
            Échéancier
          </Typography>
          <ol style={{ minHeight: "290px" }}>
            {echeancier.map(({ date, montant }) => (
              <li>
                {`${date} : `}
                <strong>{`${montant} €`}</strong>
              </li>
            ))}
          </ol>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <AjouterPaiementButton values={echeancier} />
      </Grid>
    </Grid>
  );
};
