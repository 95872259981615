import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { withRouter } from "react-router-dom";
import { Mutation } from '@apollo/client/react/components';
import CopyIcon from "@mui/icons-material/FileCopy";
import { GET_MAILINGS } from "../../hooks/useGetMailings";
import { INSERT_MAILING } from "./NouveauMailingButton";

const DuplicateMailing = ({ mailing, history }) => {
  return (
    <Mutation mutation={INSERT_MAILING}>
      {(insert_Mjc_mailing, { loading, error, data }) => (
        <DuplicateMailingButton
          history={history}
          insert_Mjc_mailing={insert_Mjc_mailing}
          loading={loading}
          error={error}
          data={data}
          mailing={mailing}
        />
      )}
    </Mutation>
  );
};

const DuplicateMailingButton = ({
  mailing,
  insert_Mjc_mailing,
  loading,
  error,
  data,
  history,
}) => {
  useEffect(() => {
    if (data) {
      history.push("/mailings");
    }
  }, [data, error, history]);

  return (
    <IconButton
      disabled={loading}
      onClick={(e) => {
        e.preventDefault();
        insert_Mjc_mailing({
          variables: {
            objet: mailing.objet,
            message: mailing.message,
            pieces_jointes: mailing.pieces_jointes,
          },
          refetchQueries: [
            {
              query: GET_MAILINGS,
            },
          ],
        });
      }}
      size="large">
      <CopyIcon />
    </IconButton>
  );
};

export default withRouter(DuplicateMailing);
