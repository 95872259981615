import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import uuidv4 from "uuid/v4";
import { capitalize } from "lodash";
import { GET_FAMILLES } from "./useGetFamilles";

const INSERT_FAMILLE = gql`
  mutation insertFamille(
    $id: uuid
    $nom: String
    $prenom: String
    $adresse: String
    $adresseComplementaire: String
    $codePostal: String
    $ville: String
    $telFixe: String
    $telPortable: String
    $telPortable2: String
    $telUrgence: String
    $email: String
    $email2: String
    $hopital: String
    $photo: Boolean
    $mailing: Boolean
    $avoir: Float
    $avoirMotif: String
    $ville_id: Int = null
  ) {
    insert_Mjc_famille(
      objects: {
        id: $id
        nom: $nom
        prenom: $prenom
        adresse: $adresse
        adresseComplementaire: $adresseComplementaire
        codePostal: $codePostal
        ville: $ville
        telFixe: $telFixe
        telPortable: $telPortable
        telPortable2: $telPortable2
        telUrgence: $telUrgence
        email: $email
        email2: $email2
        hopital: $hopital
        photo: $photo
        mailing: $mailing
        avoir: $avoir
        avoirMotif: $avoirMotif
        ville_id: $ville_id
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const useCreateFamille = () => {
  const [
    insertFamille,
    { loading: creatingFamille, error: ErrorCreatingFamille },
  ] = useMutation(INSERT_FAMILLE);

  const createFamille = async ({ nom, prenom, ...rest}) => {
    try {
      const response = await insertFamille({
        variables: {
          ...rest,
          id: uuidv4(),
          nom: nom.toUpperCase(),
          prenom: capitalize(prenom),
          photo: true,
        },
        refetchQueries: [
          {
            query: GET_FAMILLES,
          },
        ],
      });
      return response.data.insert_Mjc_famille.returning[0].id;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  return { createFamille, creatingFamille, ErrorCreatingFamille };
};
