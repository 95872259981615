import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(theme => ({
    progress: {
      margin: theme.spacing(2),
    },
    container: {
        width: "100%",
        minHeight: "calc(100vh -180px)"
    }
  }));

export default function Loader() {
    const classes = useStyles();
  
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.container}>
          <Grid item>
        <CircularProgress className={classes.progress} />
          </Grid>
      </Grid>
    );
  }
  