import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { useDeleteEnvoi } from "../../hooks/useDeleteEnvoi";

const i18nMapping = {
  open: 'Ouvert',
  bounce: 'Rebond',
  blocked: 'Bloqué',
  spam: 'Spam'
}

const parseResultat = (resultat) => resultat === null ? "" : ` - ${i18nMapping[resultat]}` || ' - undefined';


const DeleteDestinataireButton = ({ envoi, envoyer }) => {
  const { deleteEnvoi  } = useDeleteEnvoi(
    envoi.mailing_id
  );

  return (
    <ListItem component={Link} to={`/mailings/${envoi.id}`}>
      {!envoyer && <ListItemIcon>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            deleteEnvoi(envoi.id);
          }}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      </ListItemIcon>}
      <ListItemText
        primary={`${envoi.email}${parseResultat(envoi.resultat)}`}
        style={{
          color: envoi.envoye
            ? "green"
            : envoi.envoye === null
            ? "red"
            : "inherit",
        }}
      />
    </ListItem>
  );
};

export default DeleteDestinataireButton;
