import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { withRouter } from "react-router-dom";
import { Mutation } from '@apollo/client/react/components';
import DeleteIcon from "@mui/icons-material/Delete";
import gql from "graphql-tag";
import { GET_ENVOIS } from "../../hooks/useGetEnvois";

const DELETE_MAILING = gql`
  mutation DeleteMailing($id: Int) {
    delete_Mjc_mailing(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const DeleteMailing = ({ id, history }) => {
  return (
    <Mutation mutation={DELETE_MAILING}>
      {(delete_Mjc_mailing, { loading, error, data }) => (
        <DeleteMailingButton
          history={history}
          delete_Mjc_mailing={delete_Mjc_mailing}
          loading={loading}
          error={error}
          data={data}
          id={id}
        />
      )}
    </Mutation>
  );
};

const DeleteMailingButton = ({
  id,
  mailingId: mailing_id,
  delete_Mjc_mailing,
  loading,
  error,
  data,
  history,
}) => {
  useEffect(() => {
    if (data) {
      history.push("/mailings");
    }
  }, [data, error, history]);

  return (
    <IconButton
      disabled={loading}
      onClick={(e) => {
        e.preventDefault();
        if (window.confirm("Supprimer ce mailing ?")) {
          delete_Mjc_mailing({
            variables: {
              id,
            },
            refetchQueries: [
              {
                query: GET_ENVOIS,
                variables: {
                  mailing_id 
                }
              },
            ],
          });
        }
      }}
      size="large">
      <DeleteIcon />
    </IconButton>
  );
};

export default withRouter(DeleteMailing);
