import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_FAMILLES_ET_PRATIQUES = gql`
  query filterByMailing {
    Mjc_famille {
      mailing
      email
      previous
      ville
      nom
      adherents {
        pratiques {
          activite_detail_id
        }
      }
    }
  }
`;

export const useGetFamillesEtPratiques = () => {
  const { loading, data, error } = useQuery(GET_FAMILLES_ET_PRATIQUES);

  return {
    loading,
    error,
    familles: data && data.Mjc_famille ? data.Mjc_famille : [],
  };
};
