import React from "react";
import { Mutation } from '@apollo/client/react/components';
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import gql from "graphql-tag";
import { GET_MAILINGS } from "../../hooks/useGetMailings";

export const INSERT_MAILING = gql`
  mutation MyMutation(
    $message: String = "Nouveau mailing"
    $objet: String = "Nouveau mailing"
    $pieces_jointes: String = null
  ) {
    insert_Mjc_mailing(
      objects: {
        envois: {
          data: {
            email: "mumuchabb@gmail.com"
            metadatas: ""
            envoye: false
            resultat: null
          }
        }
        envoyer: false
        message: $message
        objet: $objet
        pieces_jointes: $pieces_jointes
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const NouveauMailingButton = () => {
  return (
    <Mutation mutation={INSERT_MAILING}>
      {(insert_Mjc_mailing, { loading, data }) => (
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            insert_Mjc_mailing({
              refetchQueries: [
                {
                  query: GET_MAILINGS,
                },
              ],
            });
          }}
          size="large">
          <AddIcon />
        </IconButton>
      )}
    </Mutation>
  );
};

export default NouveauMailingButton;
