import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import queries from "../queries";

const useStyles = makeStyles((theme) => ({
  noMargin: {
    marginTop: 0,
  },
}));

const RechercheForm = ({ setSearchDatas, client }) => {
  const classes = useStyles();
  const [recherche, setRecherche] = useState("");
  const handleChange = (e) => setRecherche(e.target.value);
  const handleSubmit = (e) => {
    e.preventDefault();
    client
      .query({
        query: queries.FIND_FAMILLES,
        variables: { pattern: `%${recherche}%` },
      })
      .then((res) => {
        setSearchDatas(res.data.Mjc_famille);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        autoFocus
        value={recherche}
        margin="dense"
        name="recherche"
        placeHolder="Recherche"
        type="text"
        onChange={handleChange}
        variant="outlined"
        className={classes.noMargin}
      />
      <Button type="submit" variant="contained">
        Rechercher
      </Button>
    </form>
  );
};

export default RechercheForm;
