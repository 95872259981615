import { useMutation } from "@apollo/client";
import gql from 'graphql-tag';
import { GET_FAMILLES_TO_UPDATE } from "./useGetFamillesToUpdate";

const UPDATE_FAMILLE_VILLE = gql`
  mutation updateFamille(
    $id: uuid
    $ville_id: Int
  ) {
    update_Mjc_famille(
      where: { id: { _eq: $id } }
      _set: {
        ville_id: $ville_id
      }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateFamilleVille = () => {
  const [handleUpdateFamille, mutationState] = useMutation(UPDATE_FAMILLE_VILLE);

  const updateFamille = async (values) => {
    try {
      const response = await handleUpdateFamille({
        variables: { ...values },
        refetchQueries: [
          {
            query: GET_FAMILLES_TO_UPDATE,
          },
        ],
      });
      return response.data.update_Mjc_famille.affected_rows;       
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  return { updateFamille, ...mutationState };
};
