import React from "react";
import Button from "@mui/material/Button";
import queries from "../../Familles/queries";
import { Mutation } from '@apollo/client/react/components';

const ModifierProfesseurButton = ({
  id,
  nom,
  prenom,
  onSuccess,
}) => {
  return (
    <Mutation mutation={queries.UPDATE_PROFESSEUR}>
      {(updateProfesseur, { loading, data }) => {
        if (data) {
          onSuccess();
        }
        return (
          <Button
          color="primary" variant="contained"          
            onClick={() =>
              updateProfesseur({
                variables: {
                  id,
                  nom,
                  prenom,
                  updatedAt: new Date()
                },
                refetchQueries: [
                  {
                    query: queries.GET_PROFESSEURS,
                  }
                ]
              })
            }
          >
            Modifier ce professeur
          </Button>
        );
      }}
    </Mutation>
  );
};

export default ModifierProfesseurButton;
