import React from "react";
import TextField from "@mui/material/TextField";

const RechercheForm = ({ recherche, setRecherche }) => {

  const handleChange = e => setRecherche(e.target.value.toLowerCase());

  return (
      <TextField
        autoFocus
        value={recherche}
        margin="dense"
        name="recherche"
        label=""
        placeholder="Recherche"
        type="text"
        variant="outlined"        
        onChange={handleChange}
      />
  );
};

export default RechercheForm;
