import { useMutation } from "@apollo/client";
import gql from 'graphql-tag';
import { GET_VILLES } from "./useGetVilles";

export const INSERT_VILLE = gql`
  mutation insertVille($nom: String!, $code_postal: String!, $inse: Boolean) {
    insert_Mjc_ville_one(object: {nom: $nom, code_postal: $code_postal, inse: $inse}) {
      id
    }
  }
`;
export const useCreateVille = () => {
  const [
    insertVille,
    { loading, error },
  ] = useMutation(INSERT_VILLE);

  const createVille = async (variables) => {
    try {
      const response = await insertVille({
        variables,
        refetchQueries: [
          {
            query: GET_VILLES
          },
        ],
      });
      return response.data.insert_Mjc_ville_one.returning[0].id;       
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  return { createVille, loading, error };
};
