import React from "react";
import queries from "../../Familles/queries";
import { Mutation } from '@apollo/client/react/components';

const SupprimeProgrammationButton = ({
  programmationId,
  activiteId
}) => {
  return (
    <Mutation mutation={queries.DELETE_PROGRAMMATION}>
      {(removeProgrammation, { loading, data }) => {
        return (
          <button
            onClick={() =>
              removeProgrammation({
                variables: { id: programmationId },
                optimisticResponse: {},
                refetchQueries: [
                  {
                    query: queries.GET_DETAILS_ACTIVITES_BY_ACTIVITE,
                    variables: { activite_id: activiteId }
                  }
                ],
              })
            }
          >
            x
          </button>
        );
      }}
    </Mutation>
  );
};

export default SupprimeProgrammationButton;
