import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const GET_FAMILLES = gql`
  query getFamilles {
    Mjc_famille(limit: 10, order_by: { createdAt: desc }) {
      id
      nom
      prenom
      adresse
      adresseComplementaire
      codePostal
      ville
      ville_id
      villeNormalisee {
        nom
        code_postal
        inse
      }
      email
      email2
      telFixe
      telPortable
      telPortable2
      telUrgence
      hopital
      mailing
      photo
      avoir
      avoirMotif
    }
  }
`;

export const useGetFamilles = () => {
  const { loading, data, error } = useQuery(GET_FAMILLES);

  return {
    loading,
    error,
    familles: data && data.Mjc_famille ? data.Mjc_famille : [],
  };
};
