import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Button, Card, CardActions, CardContent } from "@mui/material";
import { useUpdateFamilleVille } from "../../hooks/useUpdateFamilleVille";

export const MajVilleFamilleForm = ({ villes, famille }) => {
  const [villeId, setVilleId] = useState(null);
  const { updateFamille } = useUpdateFamilleVille();

  const handleChange = (e) => setVilleId(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateFamille({ id: famille.id, ville_id: villeId });
  };

  useEffect(() => setVilleId(null), [famille]);

  return (
    <Card
      onSubmit={handleSubmit}
      sx={{ marginBottom: 3, bgcolor: "#FBFBFB" }}
      component="form"
      variant="outlined"
    >
      <CardContent>
        <Typography variant="h5">
          {famille.nom} {famille.ville}
        </Typography>
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <Select
            sx={{ bgcolor: "#FFF" }}
            value={villeId}
            onChange={handleChange}
            name="villeId"
            inputProps={{
              name: "villeId",
              id: "villeId",
            }}
          >
            {villes.map((v) => (
              <MenuItem value={v.id}>
                {v.nom} - {v.code_postal}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
      <CardActions>
        <Button type="submit" variant="outlined" sx={{ bgcolor: "#FFF" }}>
          Valider
        </Button>
      </CardActions>
    </Card>
  );
};
