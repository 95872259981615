import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import { List, Typography } from "@mui/material";
import DeletePaiementButton from "./DeletePaiementButton";
import AjouterPaiements from "./AjouterPaiements";

const datesPaiement = [
  "30/09/2024",
  "30/10/2024",
  "30/11/2024",
  "30/12/2024",
  "30/01/2025",
  "30/02/2025",
  "30/03/2025",
  "30/04/2025",
  "30/05/2025",
  "30/06/2025",
  "30/07/2025",
  "30/08/2025",
];

const ListePaiements = ({ famille_id, paiements, total, totalPaiements }) => {
  
  const manque =  total - totalPaiements;
  console.log({ manque });
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Tooltip
          title={manque !== 0 ? `- ${manque.toFixed(2)} €` : "paiements 100%"}
        >
          <Typography
            align="center"
            variant="h5"
            color={manque === 0 ? "inherit" : "red"}
            sx={{ cursor: "pointer" }}
          >
            {paiements.length > 0 ? paiements.length : ""} Paiements{" "}
            {`(${total.toFixed(2)} €)`}
          </Typography>
        </Tooltip>
        <List component="nav" style={{ maxHeight: "150px", overflow: "auto" }}>
          {paiements.map((p) => (
            <ListItem key={p.id}>
              <ListItemText primary={`${p.date} ${p.type} ${p.montant} €`} />
              <DeletePaiementButton id={p.id} famille_id={famille_id} />
            </ListItem>
          ))}
        </List>
      </CardContent>
      {manque > 0 && (
        <CardActions>
          <AjouterPaiements
            famille_id={famille_id}
            dates={datesPaiement}
            manque={manque}
          />
        </CardActions>
      )}
    </Card>
  );
};

export default ListePaiements;
