import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const GET_FAMILLE_PAIEMENTS = gql`
  query getPaiements($famille_id: uuid) {
    Mjc_paiement(where: { famille_id: { _eq: $famille_id } }) {
      id
      date
      type
      montant
    }
  }
`;

export const useGetFamillePaiements = (famille_id) => {
  const { loading, data, error } = useQuery(GET_FAMILLE_PAIEMENTS, {
    variables: { famille_id },
    pollInterval: 1000,
  });

  return {
    loading,
    paiements: data && data.Mjc_paiement ? data.Mjc_paiement : null,
    error,
  };
};
