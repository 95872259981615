import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AjouterPaiementButton from "./AjouterPaiementButton";
import SelectTypePaiement from "./SelectTypePaiement";
import Typography from "@mui/material/Typography";

const PaiementsManuelsForm = ({ dates, famille_id, manque }) => {
  const [values, setValues] = useState({ montant: manque ? manque.toFixed(2) : ''});

  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  return (
    <>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <Typography id="input-slider" gutterBottom>
          Date
        </Typography>
        <Select
          value={values.date}
          onChange={handleChange}
          inputProps={{
            name: "date",
            id: "date",
          }}
        >
          {dates.map((d) => (
            <MenuItem value={d} key={d}>
              {d}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SelectTypePaiement handleChange={handleChange} type={values.type} />
      <FormControl fullWidth sx={{ marginTop: 2 }}>
        <Typography id="input-slider" gutterBottom>
          Montant
        </Typography>
        <TextField
          name="montant"
          value={values.montant}
          type="text"
          fullWidth
          onChange={handleChange}
        />
      </FormControl>
      <AjouterPaiementButton values={[{ ...values, famille_id }]} />
    </>
  );
};

export default PaiementsManuelsForm;
