import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Query } from "@apollo/client/react/components";
import { Link } from "react-router-dom";
import queries from "../Familles/queries";
import Loader from "../common/Loader";
import GenerateButton from "./GenerateButton";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // overflowX: 'auto',
    // maxHeight: `calc(100vh - 185px)`
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const PaiementsQuery = () => {
  const classes = useStyles();

  return (
    <Query query={queries.GET_PAIEMENTS}>
      {({ loading, error, data, client }) => {
        if (loading) {
          return <Loader />;
        }

        if (error) {
          console.error(error);

          return <div>Error!</div>;
        }

        const payments = data.Mjc_paiement.reduce(
          (memo, p) => ({
            ...memo,
            [p.date]: {
              ...memo[p.date],
              montant: (memo[p.date] ? memo[p.date].montant : 0) + p.montant,
              familles: (memo[p.date] ? memo[p.date].familles : []).concat([
                { ...p.famille, montant: p.montant, type: p.type },
              ]),
              [p.type]:
                (memo[p.date] && memo[p.date][p.type]
                  ? memo[p.date][p.type]
                  : 0) + p.montant,
            },
          }),
          {}
        );

        return (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                to="/anomalies_paiement"
                component={Link}
              >
                Anomalies de paiement
              </Button>
            </Grid>
            <Grid item>
              <Paper className={classes.root}>
                {Object.keys(payments)
                  .sort((p1, p2) => {
                    const keyR1 = p1.split("/").reverse().join();
                    const keyR2 = p2.split("/").reverse().join();
                    if (keyR1 > keyR2) return 1;
                    if (keyR1 < keyR2) return -1;
                    return 0;
                  })
                  .map((date) => {
                    return (
                      <div style={{ marginTop: "1em" }} key={date}>
                        {date} <GenerateButton paiements={payments[date]} />
                      </div>
                    );
                  })}
              </Paper>
            </Grid>
          </Grid>
        );
      }}
    </Query>
  );
};
export default PaiementsQuery;
