import React, { useState, useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CreerProfesseurButton from "./CreerProfesseurButton";
import ModifierProfesseurButton from "./ModifierProfesseurButton";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    width: "50%",
    padding: theme.spacing(2)
  },
  label: {
    paddingLeft: theme.spacing(2)
  }
}));

const ProfesseurForm = props => {
  const {
    handleClose,
    open,
    professeur,
    loading,
    error,
    data
  } = props;
  const [values, setValues] = useState(professeur);
  const classes = useStyles();

  useEffect(() => setValues(professeur), [professeur]);

  const handleChange = e =>
    setValues({ ...values, [e.target.name]: e.target.value });

  return (
    <Dialog
      open={open && !data}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle name="form-dialog-title">
        {!professeur.id ? "Ajouter " : "Modifier "} un professeur {loading && "..."}
        {error && "!!!!"}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          value={values.nom}
          margin="dense"
          name="nom"
          label="Nom"
          type="text"
          required
          className={classes.input}
          InputLabelProps={{ className: classes.label }}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="prenom"
          value={values.prenom}
          label="Prenom"
          type="text"
          className={classes.input}
          InputLabelProps={{ className: classes.label }}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        {!professeur.id && <CreerProfesseurButton {...values} onSuccess={handleClose}/>}
        {professeur.id && <ModifierProfesseurButton {...values} id={professeur.id} onSuccess={handleClose}/>}
      </DialogActions>
    </Dialog>
  );
};

export default ProfesseurForm;
