import React, { useState, useEffect } from "react";
import { Mutation } from '@apollo/client/react/components';
import { withRouter } from "react-router-dom";
import Grid from "@mui/material/Grid";
import ListeActivites from "./components/ListeActivites";
import ListeProgrammation from "./components/ListeProgrammation";
import ProgrammationForm from "./components/ProgrammationForm";
import queries from "../Familles/queries";

const Programmation = ({ match }) => {
  const [toEdit, setToEdit] = useState(null);
  const [creationMode, setCreationMode] = useState(false);
  useEffect(() => {
    setToEdit(null);
    setCreationMode(false);
  }, [match.params.id]);

  const handleSetCreationMode = () => setCreationMode(true);

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={3}>
        <ListeActivites activiteId={match.params.id} />
      </Grid>
      <Grid item xs={4}>
        <h1>
          Programmations{" "}
          {match.params.id && !creationMode && (
            <button onClick={handleSetCreationMode}>+</button>
          )}
        </h1>
        {match.params.id && (
          <ListeProgrammation activiteId={match.params.id} onEdit={setToEdit} />
        )}
      </Grid>
      <Grid item xs={5}>
        {match.params.id && creationMode && !toEdit && (
          <Mutation mutation={queries.INSERT_PROGRAMMATION}>
            {(addProgrammation, { loading, data }) => {
              return (
                <ProgrammationForm
                  data={data}
                  activiteDetail={{}}
                  loading={loading}
                  onCancel={() => setCreationMode(false)}
                  activiteId={match.params.id}
                  mutateProgrammation={addProgrammation}
                />
              );
            }}
          </Mutation>
        )}
        {toEdit && (
          <Mutation mutation={queries.UPDATE_PROGRAMMATION}>
            {(updateProgrammation, { loading, data }) => {
              return (
                <ProgrammationForm
                  data={data}
                  activiteDetail={toEdit}
                  loading={loading}
                  onCancel={() => setToEdit(false)}
                  activiteId={match.params.id}
                  mutateProgrammation={updateProgrammation}
                />
              );
            }}
          </Mutation>
        )}
      </Grid>
    </Grid>
  );
};

export default withRouter(Programmation);
