import React, { useState } from "react";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useGetFamille } from "../../hooks/useGetFamille";
import { calculeFacture } from "../../common/calculeFacture";
import PaiementsManuelsForm from "./PaiementsManuelsForm";
import { PaiementsAutoForm } from "./PaiementsAutoForm";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const AjouterPaiements = ({ dates, famille_id, manque }) => {
  const { famille, loading, error } = useGetFamille(famille_id);
  const [value, setValue] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  if (loading) {
    return null;
  }

  if (error) {
    console.error(error);
    return <div>Error!</div>;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const { total } = calculeFacture(famille);

  return (
    <>
      <Button type="submit" onClick={() => setIsOpen(true)}>
        {" "}
        Ajouter
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle name="form-dialog-title">
          Ajouter <strong>{manque.toFixed(2)} €</strong> sur une facture de {total.toFixed(2)} €
        </DialogTitle>
        <DialogContent sx={{ minHeight: "500px" }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Automatique" {...a11yProps(0)} />
                <Tab label="Manuel" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <PaiementsAutoForm
                dates={dates}
                famille_id={famille_id}
                total={manque}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PaiementsManuelsForm famille_id={famille_id} dates={dates} manque={manque} />
            </TabPanel>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AjouterPaiements;
