import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_FAMILLES_SAISON = gql`
  query queryFamillesSaison {
    Mjc_famille {
      id
      nom
      prenom
      adherents {
        pratiques_aggregate {
          aggregate {
            count(columns: activite_detail_id)
          }
        }
      }
    }
  }
`;

export const useGetFamillesSaison = () => {
  const { loading, data, error } = useQuery(GET_FAMILLES_SAISON);

  return {
    loading,
    error,
    familles:
      data && data.Mjc_famille
        ? data.Mjc_famille
        .filter((f) =>
            f.adherents.find((a) => a.pratiques_aggregate.aggregate.count > 0)
          )
        : [],
  };
};
