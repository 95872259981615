import React, { useState } from "react";
import { Link } from "react-router-dom";
import { matchSorter } from "match-sorter";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Loader from "../common/Loader";
import { useGetAdherents } from "../hooks/useGetAdherents";
import { TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    maxHeight: `calc(100vh - 185px)`,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Adherents = () => {
  const classes = useStyles();
  const [recherche, setRecherche] = useState("");
  const { loading, error, adherents } = useGetAdherents();

  if (loading) {
    return <Loader />;
  }

  if (error) return <p>{JSON.stringify(error)}</p>;

  const handleChangeRecherche = (e) => setRecherche(e.target.value);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid
        container
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        alignContent="center"
      >
        <Grid item>
          <TextField
            autoFocus
            value={recherche}
            margin="dense"
            name="recherche"
            placeHolder="Recherche"
            type="text"
            onChange={handleChangeRecherche}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid item>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Nom</TableCell>
                <TableCell align="center">Prénom</TableCell>
                <TableCell align="center">Famille</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matchSorter(adherents, recherche, { keys: ["nom"] }).map(
                (row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center" component="th" scope="row">
                      {row.nom}
                    </TableCell>
                    <TableCell align="center">{row.prenom}</TableCell>
                    <TableCell align="center">
                      <Link to={`/familles/${row.famille.id}`}>
                        {row.famille.nom} {row.famille.prenom}
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default Adherents;
