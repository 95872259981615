import React from "react";
import { withRouter } from "react-router-dom";
import ListeMailings from "./components/ListeMailings";
import ListeDestinataires from "./components/ListeDestinataires";
import Grid from "@mui/material/Grid";
import CommunicationForm from "./components/CommunicationForm";
import { useGetEnvois } from "../hooks/useGetEnvois";
import { Typography } from "@mui/material";
import { useGetMailings } from "../hooks/useGetMailings";

const MailingContainer = ({ match }) => {
  const { mailings, loading, error } = useGetMailings();
  if (loading) {
    return "loading...";
  }

  if (error) return "Error...";

  return <Mailing mailings={mailings} mailingId={match.params.id} />;
};

const Mailing = ({ mailingId }) => {
  const { mailings, loading, error } = useGetMailings();
  const {
    destinataires,
    loading: loadingDestinataires,
    error: errorLoadingDestinataires,
  } = useGetEnvois(mailingId);


  if (loading) {
    return "loading...";
  }

  if (error) return "Error...";

  const mailing = mailings.find((m) => m.id === parseInt(mailingId, 10));

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item container direction="row" xs={6}>
        <Grid item xs={4}>
          <ListeMailings mailings={mailings} idSelected={mailingId} />
        </Grid>
        <Grid item xs={6}>
          {destinataires.length > 0 && (
            <ListeDestinataires
              destinataires={destinataires}
              mailingId={mailingId}
              envoyer={mailing.envoyer}
            />
          )}
          {mailingId && loadingDestinataires && (
            <Typography style={{ padding: "20px" }}>Chargement...</Typography>
          )}
          {errorLoadingDestinataires && mailingId && (
            <Typography style={{ padding: "20px" }}>
              Erreur chgt destinataires
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={6} container spacing={1} direction="column" justifyContent="space-between">
        {mailingId && <CommunicationForm mailingId={mailingId} />}
      </Grid>
    </Grid>
  );
};

export default withRouter(MailingContainer);
