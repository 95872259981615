import { useMutation } from "@apollo/client";
import gql from 'graphql-tag';
import { GET_ENVOIS } from "./useGetEnvois";

export const DELETE_ENVOI = gql`
  mutation deleteDestinataire($id: Int) {
    delete_Mjc_envoi(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const useDeleteEnvoi = (mailing_id) => {
  const [
    delEnvoi,
    { loading: deleteing, error: errorDeleting },
  ] = useMutation(DELETE_ENVOI);

  const handleDeleteEnvoi = async (id) => {
    try {
      const response = await delEnvoi({
        variables: { id },
        refetchQueries: [
          {
            query: GET_ENVOIS,
            variables: { mailing_id }
          },
        ],
      });
      return response.data.insert_Mjc_envoi.returning[0].id;       
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  return { deleteEnvoi: handleDeleteEnvoi, deleteing, errorDeleting };
};
