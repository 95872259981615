import React, { useState, useEffect } from "react";
import uuidv4 from "uuid/v4";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import SelectProfesseur from "./SelectProfesseur";
import SelectLieu from "./SelectLieu";
import SelectJourSemaine from "./SelectJourSemaine";
import SelectPublic from "./SelectPublic";
import queries from "../../Familles/queries";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  textField: {
    width: "50%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  label: {
    paddingLeft: theme.spacing(2)
  }
}));

const ProgrammationForm = ({
  activiteId,
  activiteDetail,
  mutateProgrammation,
  onCancel
}) => {
  const classes = useStyles();
  const [values, setValues] = useState(activiteDetail);

  useEffect(() => {
    setValues({ ...activiteDetail });
  }, [activiteDetail]);

  const handleChange = e =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const handleChangeSwitch = (name) => (event) => {
    setValues({ ...values, [name]: event.target.checked });
  };


  const handleSubmit = e => {
    e.preventDefault();
    const { __typename, professeur, activite, id, ...restValues } = values;
    mutateProgrammation({
      variables: {
        ...restValues,
        id: id || uuidv4(),
        activite_id: activiteId
      },
      refetchQueries: [
        {
          query: queries.GET_DETAILS_ACTIVITES_BY_ACTIVITE,
          variables: { activite_id: activiteId }
        }
      ]
    });
  };
  return (
    <Grid container direction="column" alignItems="flex-start">
      <form onSubmit={handleSubmit}>
        <Grid item>
          <SelectLieu handleChange={handleChange} lieu={values.lieu} />
        </Grid>
        <Grid item>
          <SelectProfesseur
            handleChange={handleChange}
            professeur_id={values.professeur_id}
          />
        </Grid>
        <Grid item>
          <SelectJourSemaine
            handleChange={handleChange}
            jour={values.semaineJour}
          />
        </Grid>
        <Grid item>
          <SelectPublic
            handleChange={handleChange}
            type_public={values.type_public}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="publicComplement"
            label="public complément"
            className={classes.textField}
            value={values.publicComplement}
            InputLabelProps={{ className: classes.label }}
            onChange={handleChange}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            name="horaireDebut"
            label="heure début"
            className={classes.textField}
            value={values.horaireDebut}
            InputLabelProps={{ className: classes.label }}
            onChange={handleChange}
            margin="normal"
            fullWidth
          />
          <TextField
            name="horaireFin"
            label="heure début"
            className={classes.textField}
            value={values.horaireFin}
            InputLabelProps={{ className: classes.label }}
            onChange={handleChange}
            margin="normal"
          />
        </Grid>
        <Grid item>
          <TextField
            name="montant"
            label="Montant"
            className={classes.textField}
            InputLabelProps={{ className: classes.label }}
            value={values.montant}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            name="montantComplement"
            label="Montant complément"
            className={classes.textField}
            InputLabelProps={{ className: classes.label }}
            value={values.montantComplement}
            onChange={handleChange}
            margin="normal"
          />
        </Grid>
        <Grid item>
          <TextField
            name="licence"
            label="Licence"
            className={classes.textField}
            InputLabelProps={{ className: classes.label }}
            value={values.licence}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            name="licenceTxt"
            label="Licence complément"
            className={classes.textField}
            InputLabelProps={{ className: classes.label }}
            value={values.licenceTxt}
            onChange={handleChange}
            margin="normal"
          />
        </Grid>
        <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={values.hors_stats}
                  onChange={handleChangeSwitch("hors_stats")}
                  value={values.hors_stats}
                />
              }
              label="Ne pas compter dans les stats"
            />
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Button type="submit" variant="outlined" color="primary">
                {activiteDetail.id ? "Modifier" : "Créer"}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={onCancel}>
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default ProgrammationForm;
