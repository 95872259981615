import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const GET_VILLES = gql`
  query getVilles {
    Mjc_ville(order_by: {nom: asc}) {
      id
      nom
      code_postal
    }
  }
`;

export const useGetVilles = () => {
  const { loading, data, error } = useQuery(GET_VILLES);

  return {
    loading,
    error,
    villes: data && data.Mjc_ville ? data.Mjc_ville : [],
  };
};
