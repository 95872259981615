import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ClipboardIcon from "@mui/icons-material/Assignment";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import uuidv4 from "uuid/v4";
import ListeActiviteDetails from "./components/ListeActiviteDetails";
import ListePratiques from "./components/ListePratiques";
import AdherentForm from "./components/AdherentForm";
import ListePaiement from "./components/ListePaiements";
import { useGetFamille } from "../hooks/useGetFamille";
import { calculeFacture } from "../common/calculeFacture";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 0,
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  form: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const adherentM = {
  id: uuidv4(),
  nom: "",
  prenom: "",
  dateNaissance: "",
  adhesion_id: "",
  reduction_id: "f0b1614e-72b8-446a-952f-ac3b4e53c11f",
};

const Famille = () => {
  const { id, adherentId } = useParams();
  const { search } = useLocation();
  const [modeAjout, setModeAjout] = useState(search === "?mode=ajout");
  const { famille, loading, error } = useGetFamille(id);
  const classes = useStyles();
  const { total, totalPaiements } = useMemo(
    () => (!famille ? {} : calculeFacture(famille)),
    [famille]
  );

  useEffect(() => {
    setModeAjout(search === "?mode=ajout");
  }, [search]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.error(error);
    return <div>Error!</div>;
  }

  const { nom, adherents, paiements } = famille;
  const adherent = adherents.find((a) => a.id === adherentId);
  const pratiques = adherent ? adherent.pratiques : [];

  return (
    <Grid
      container
      className={classes.root}
      spacing={2}
      justifyContent="flex-start"
    >
      <Grid item sm={3}>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Typography align="center" variant="h5">
              Famille <strong>{nom}</strong>
            </Typography>
            <ul style={{ listStyleType: "none" }}>
              {famille.email && (
                <li>
                  {famille.email}
                  <IconButton
                    title="Copier dans le presse-papier"
                    onClick={() => {
                      navigator.clipboard.writeText(famille.email);
                    }}
                  >
                    <ClipboardIcon />
                  </IconButton>
                </li>
              )}
              {famille.email2 && (
                <li>
                  {famille.email2}
                  <IconButton
                    title="Copier dans le presse-papier"
                    onClick={() => {
                      navigator.clipboard.writeText(famille.email2);
                    }}
                  >
                    <ClipboardIcon />
                  </IconButton>
                </li>
              )}
              {famille.telFix && <li>{famille.telFix}</li>}
              {famille.telPortable && <li>{famille.telPortable}</li>}
              {famille.telPortable2 && <li>{famille.telPortable2}</li>}
            </ul>
          </CardContent>
          <CardActions>
            <Button component={Link} to={`/factures/${id}`}>
              Facture
            </Button>
          </CardActions>
        </Card>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Typography align="center" variant="h5">
              Adherents
            </Typography>
            <List component="nav">
              {adherents.map((a, idx) => (
                <ListItem
                  selected={a.id === adherentId}
                  component={Link}
                  to={`/familles/${id}/adherents/${a.id}`}
                  key={idx}
                >
                  <ListItemText primary={`${a.nom} ${a.prenom}`} />
                </ListItem>
              ))}
            </List>
          </CardContent>
          <CardActions>
            <Button component={Link} to={`/familles/${id}?mode=ajout`}>
              Ajouter
            </Button>
          </CardActions>
        </Card>
        <ListePaiement
          famille_id={id}
          paiements={paiements}
          total={total}
          totalPaiements={totalPaiements}
        />
      </Grid>
      <Grid item sm={6}>
        {(adherentId || modeAjout) && famille && (
          <Paper className={classes.form}>
            <AdherentForm
              adherent={
                modeAjout ? { ...adherentM, nom: famille.nom } : adherent
              }
              familleId={id}
              inse={famille.ville_id ? famille.villeNormalisee.inse : null}
              modeAjout={modeAjout}
              nom={famille.nom}
            />
          </Paper>
        )}
        {adherentId && !modeAjout && (
          <ListePratiques pratiques={pratiques} familleId={id} />
        )}
      </Grid>
      <Grid item sm={3}>
        {adherentId && (
          <ListeActiviteDetails
            familleId={id}
            pratiques={pratiques}
            adherentId={adherentId}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default Famille;
