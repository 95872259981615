import React from "react";
import { Query } from "@apollo/client/react/components";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import queries from "../../Familles/queries";

const SelectReduction = ({ adhesion_id, handleChange, inse }) => {
  return (
    <Query query={queries.GET_ADHESIONS}>
      {({ loading, error, data, client }) => {
        if (error) {
          console.error(error);
          return <div>Error!</div>;
        }
        return (
          <>
            <InputLabel htmlFor="age-simple">Adhesion</InputLabel>
            <Select
              value={adhesion_id}
              onChange={handleChange}
              fullWidth
              inputProps={{
                name: "adhesion_id",
                id: "adhesion_id",
              }}
            >
              {!loading &&
                data.Mjc_adhesion.filter(
                  (a) =>
                    inse === null ||
                    (inse ? !a.nom.includes("INSE") : a.nom.includes("INSE"))
                ).map(((p, idx) => (
                  <MenuItem value={p.id} key={idx + 1}>
                    {`${p.nom} (${p.montant} €)`}
                  </MenuItem>
                )))}
              {loading && <MenuItem value="" key={0}>...</MenuItem>}
            </Select>
          </>
        );
      }}
    </Query>
  );
};

export default SelectReduction;
