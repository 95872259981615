import React from "react";
import { Link } from "react-router-dom";
import { fr } from "date-fns/locale";
import { format, parseISO } from "date-fns";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import NouveauMailingButton from "./NouveauMailingButton";
import { Typography } from "@mui/material";

const ListeMailings = ({ mailings, idSelected }) => {
  return (
    <div style={{ padding: "0 20px" }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Mailings</Typography>
        </Grid>
        <Grid item>
          <NouveauMailingButton />
        </Grid>
      </Grid>
      <List
        component="nav"
        style={{ maxHeight: "calc(100vh - 250px)", overflow: "auto" }}
      >
        {mailings.map((m, idx) => (
          <ListItem
            key={idx}
            component={Link}
            selected={m.id === parseInt(idSelected, 10)}
            to={`/mailings/${m.id}`}
            style={{
              borderLeft: `solid 5px ${m.envoyer ? "green" : "transparent"}`,
            }}
          >
            <ListItemText
              primary={m.objet}
              secondary={format(parseISO(m.updated_at), "PPpp", { locale: fr })}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default ListeMailings;
