import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const mediaHost = process.env.NODE_ENV !== "production" ? "http://localhost:8081" : "";

const Login = ({ handleLogin }) => {
  const [values, setValues] = useState({ email: "regisg@gmail.com", password: "Rg27Gu+1" });
  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${mediaHost}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email: values.email,
        password: values.password,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        handleLogin(res.token || "oo");
      });
  };

  const handleChange = (e) => setValues({ ...values, [e.target.name]: e.target.value });

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item component="form" onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField label="email" type="email" onChange={handleChange} name="email" fullWidth />
          </Grid>
          <Grid item>
            <TextField label="mot de passe" type="password" onChange={handleChange} name="password" fullWidth />
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" type="submit">
              Valider
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
