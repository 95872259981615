import React from "react";
import Button from "@mui/material/Button";
import queries from "../../Familles/queries";
import { Mutation } from "@apollo/client/react/components";
import { capitalize } from "lodash";

const ModifierAdherentButton = ({
  id,
  nom,
  prenom,
  dateNaissance,
  famille_id,
  adhesion_id,
  reduction_id,
}) => {
  return (
    <Mutation mutation={queries.UPDATE_ADHERENT}>
      {(updateAdherent, { loading, data, onComplete }) => {
        if (typeof data !== "undefined") {
          console.log("complete !", data);
        }
        return (
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            onClick={() =>
              updateAdherent({
                variables: {
                  id,
                  nom: nom.toUpperCase(),
                  prenom: capitalize(prenom),
                  dateNaissance,
                  adhesion_id,
                  reduction_id,
                  famille_id,
                },
                refetchQueries: [
                  {
                    query: queries.GET_FAMILLE,
                    variables: { id: famille_id },
                  },
                ],
              })
            }
          >
            Modifier
          </Button>
        );
      }}
    </Mutation>
  );
};

export default ModifierAdherentButton;
