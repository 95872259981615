import { flatten } from "lodash";

export const calculeFacture = (famille) => {
  const { adherents, avoir, paiements } = famille;

  const totalActivites =
    flatten(
      adherents.map((a) => a.pratiques.map((p) => p.activite_detail.montant))
    ).reduce((memo, montant) => memo + montant, 0) - (avoir || 0);

  const totalLicences = flatten(
    adherents.map((a) =>
      a.pratiques
        .filter((p) => p.activite_detail.licence)
        .map((p) => p.activite_detail.licence)
    )
  ).reduce((memo, licence) => memo + licence, 0);

  const adhesions = flatten(adherents.map((adh) => adh && adh.adhesion));
  const adhFamille = adhesions.find((a) => a && a.nom.includes("Famille"));
  const adhesionsFiltred = adhFamille ? [adhFamille] : adhesions;
  const totalAdhesions = adhesionsFiltred.reduce(
    (memo, adh) => memo + (adh ? adh.montant : 0),
    0
  );

  const avecReduction = adherents.reduce(
    (memo, adh) => memo || (adh.reduction && adh.reduction.nom !== "Aucune"),
    false
  );

  const reductionActivites = totalActivites >= 656 ? totalActivites * 0.1 : 0;

  const reductionAdh = avecReduction
    ? (totalActivites - reductionActivites) * 0.1
    : 0;

  const totalPaiements = paiements.reduce((acc, p) => acc + p.montant, 0);

  const total =
    totalActivites +
    totalLicences +
    totalAdhesions -
    reductionActivites -
    reductionAdh;

  return {
    adherents,
    adhFamille,
    avoir,
    totalActivites,
    totalLicences,
    totalAdhesions,
    reductionActivites,
    reductionAdh,
    total,
    totalPaiements
  };
};
