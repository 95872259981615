import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Query } from '@apollo/client/react/components';
import queries from "../Familles/queries";
import ProfesseurForm from "./components/ProfesseurForm";
import RechercheForm from "./components/RechercheForm";
import SupprimerProfesseurButton from "./components/SupprimerProfesseurButton";
import Loader from "../common/Loader";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    overflowX: "auto",
    maxHeight: `calc(100vh - 200px)`
  },
}));

const FamillesQuery = ({ logout }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [recherche, setRecherche] = useState("");
  const [professeurSelected, selectProfesseur] = useState(null);
  const closeDialog = () => {
    selectProfesseur(null);
    setDialogOpen(false);
  };
  const openDialog = () => setDialogOpen(true);

  return (
    <Query query={queries.GET_PROFESSEURS}>
      {({ loading, error, data, client }) => {
        if (loading) {
          return <Loader />;
        }

        if (error) {
          console.error(error);

          return (
            <div>
              Error! <button onClick={logout}>Logout</button>
            </div>
          );
        }

        return (
          <Grid container direction="column" spacing={2}>
            <ProfesseurForm
              professeur={professeurSelected || {}}
              open={dialogOpen || professeurSelected}
              handleClose={closeDialog}
            />
            <Grid item>
              <Grid
                container
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    onClick={openDialog}
                    color="primary"
                    variant="contained"
                  >
                    + Nouveau professeur
                  </Button>
                </Grid>
                <Grid item>
                  <RechercheForm
                    recherche={recherche}
                    setRecherche={setRecherche}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Nom</TableCell>
                      <TableCell align="center">Prénom</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.Mjc_professeur.filter(
                      p =>
                        recherche === "" ||
                        p.nom.toLowerCase().indexOf(recherche) !== -1
                    ).map(row => (
                      <TableRow key={row.id}>
                        <TableCell align="center" component="th" scope="row">
                          {row.nom}
                        </TableCell>
                        <TableCell align="center">{row.prenom}</TableCell>
                        <TableCell align="right">
                          <ButtonGroup size="small">
                            <Button
                              onClick={() => selectProfesseur(row)}
                              variant="outlined"
                              color="primary"
                              className={classes.button}
                            >
                              <EditIcon title="modifier" />
                            </Button>
                            <SupprimerProfesseurButton
                              professeurId={row.id}
                              className={classes.button}
                              disabled={row.activites.length > 0}
                            />
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        );
      }}
    </Query>
  );
};
export default FamillesQuery;
