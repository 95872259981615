import React from "react";
import uuidv4 from "uuid/v4";
import Button from "@mui/material/Button";
import queries from "../../Familles/queries";
import { Mutation } from '@apollo/client/react/components';

const CreerProfesseurButton = ({ nom, prenom, onSuccess }) => {
  return (
    <Mutation mutation={queries.INSERT_PROFESSEUR}>
      {(addProfesseur, { loading, data }) => {
        if (data) {
          onSuccess();
        }        
        return (
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              addProfesseur({
                variables: {
                  id: uuidv4(),
                  nom,
                  prenom,
                  createdAt: new Date(),
                  updatedAt: new Date()
                },
                refetchQueries: [
                  {
                    query: queries.GET_PROFESSEURS
                  }
                ]
              })
            }
          >
            Créer ce professeur
          </Button>
        );
      }}
    </Mutation>
  );
};

export default CreerProfesseurButton;
