import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const jours = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];

const SelectJourSemaine = ({ jour, handleChange }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} fullWidth>
      <InputLabel htmlFor="age-simple">Jour</InputLabel>
      <Select
        value={jour}
        onChange={handleChange}
        inputProps={{
          name: "semaineJour",
          id: "semaineJour-simple"
        }}
      >
        {jours.map(j => (
          <MenuItem value={j}>{j}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectJourSemaine;
