import React from "react";
import { useGetVilles } from "../hooks/useGetVilles";
import Grid from "@mui/material/Grid";
import { AjouterVilleForm } from "./components/AjouterVilleForm";
import { ListeFamillesToUpdate } from "./components/ListeFamillesToUpdate";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const Villes = () => {
  const { villes, loading } = useGetVilles();

  if (loading) return null;

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <AjouterVilleForm />        
        <List sx={{ maxHeight: "calc(100vh - 250px)", overflow:"auto"}}>
          {villes.map((v) => (
            <ListItem key={v.id} dense>
                <ListItemText primary={v.nom} />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={8}>
        {<ListeFamillesToUpdate villes={villes} />}
      </Grid>
    </Grid>
  );
};

export default Villes;
