import React, { useState } from "react";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import gql from "graphql-tag";
import DeleteDestinataireButton from "./DeleteDestinataireButton";
import AjouterDestinataireForm from "./AjouterDestinataireForm";
import { Typography } from "@mui/material";

export const GET_ENVOIS = gql`
  query getDestinataires($mailing_id: Int) {
    Mjc_envoi(where: { mailing_id: { _eq: $mailing_id } }) {
      lu
      id
      envoye
      email
      mailing_id
    }
  }
`;

const ListeDestinataires = ({ mailingId, destinataires, envoyer }) => {
  const [recherche, setRecherche] = useState("");
  const handleChange = (e) => setRecherche(e.target.value);
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {!destinataires.length ? (
            ""
          ) : (
            <Typography variant="h4">
              {destinataires.length} destinataires
            </Typography>
          )}
        </Grid>
        {!envoyer && (
          <Grid item>
            <AjouterDestinataireForm
              mailingId={mailingId}
              destinataires={destinataires}
            />
          </Grid>
        )}
      </Grid>
      <Grid item>
        <TextField onChange={handleChange} label="recherche" fullWidth />
      </Grid>
      <List
        component="nav"
        style={{ maxHeight: "calc(100vh - 250px)", overflow: "auto" }}
      >
        {destinataires
          .filter(
            (e) =>
              recherche === "" ||
              e.email.toLowerCase().indexOf(recherche.toLowerCase()) !== -1
          )
          .map((m, idx) => (
            <DeleteDestinataireButton
              envoi={m}
              mailingId={mailingId}
              key={idx}
              envoyer={envoyer}
            />
          ))}
      </List>
    </>
  );
};

export default ListeDestinataires;
