import React, { useState, useEffect } from "react";
import { InMemoryCache } from "@apollo/client/cache";
import { HttpLink, ApolloProvider, ApolloClient } from "@apollo/client";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import AppBar from "./AppBar";
import MainRouter from "./pages/MainRouter";
import Login from "./pages/Login";

const hasuraAuth =
  process.env.NODE_ENV === "production"
    ? {}
    : { "x-hasura-admin-secret": "Rg27Gu+1" };

const createApolloClient = (authToken) =>
  new ApolloClient({
    link: new HttpLink({
      uri: "/v1/graphql",
      headers: {
        Authorization: `Bearer ${authToken}`,
        ...hasuraAuth,
      },
    }),

    cache: new InMemoryCache(),
  });

const App = () => {
  const [token, setToken] = useState(null);
  const [triedFromLocalStorage, setTriedFromLocalStorage] = useState(false);

  const handleLogin = (token) => {
    window.sessionStorage.setItem("token", token);
    setToken(token);
  };

  useEffect(() => {
    const token = window.sessionStorage.getItem("token");
    if (token) {
      setToken(token);
    }
    setTriedFromLocalStorage(true);
  }, []);

  if (!triedFromLocalStorage) return null;

  if (!token) {
    return <Login handleLogin={handleLogin} />;
  }

  const client = createApolloClient(token);
  const isFacture = `${document.location}`.indexOf("facture") !== -1;
  return (
    <ApolloProvider client={client}>
      {!isFacture && <AppBar />}
      {!isFacture && (
        <Container maxWidth="xl">
          <Box my={4}>
            <MainRouter />
          </Box>
        </Container>
      )}
      {isFacture && <MainRouter />}
    </ApolloProvider>
  );
};

export default App;
